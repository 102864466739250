export default class NewUserEmailProvided extends Event {
  public static type = "user.email-provided";

  constructor(
    public readonly email: string,
    public readonly source?: string,
  ) {
    super(NewUserEmailProvided.type);
  }
}
