// ** Util Imports
import { Components, Theme } from "@mui/material";

const Form: Partial<Components<Theme>> = {
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: 1.66667,
      },
    },
  },
};

export default Form;
