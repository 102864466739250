import { Box, Link, Stack, Typography, typographyClasses } from "@mui/material";
import { EmailDetail } from "@unkover/unkover-api-sdk/dist/Model/Email";
import FormattedDate from "components/date/FormattedDate";
import HtmlEmbed from "components/emailFlows/view/HtmlEmbed";
import { ReactElement } from "react";

function wrapEmailAccount(
  email: string,
  maxAccountLength = 20,
  ellipsis = "...",
): string {
  const emailParts = email.split("@");
  const emailName = emailParts[0];
  const emailDomain = emailParts[1];

  if (
    undefined === emailName ||
    undefined === emailDomain ||
    emailName.length - ellipsis.length <= maxAccountLength
  )
    return email;

  return `${emailName.slice(0, maxAccountLength)}${ellipsis}@${emailDomain}`;
}

type EmailViewProps = Readonly<{ email: EmailDetail }>;
export default function EmailView({ email }: EmailViewProps): ReactElement {
  return (
    <Stack spacing={5} direction="column" sx={{ p: 6, pt: 3, width: "100%" }}>
      <Box>
        <Typography variant="body2" fontWeight={600} color="text.primary">
          <FormattedDate date={email.createdAt} variant="full" />
        </Typography>
        <Box
          sx={{
            pt: 2,
            [`& .${typographyClasses.root}:not(:first-of-type)`]: { mt: 1 },
          }}
        >
          <Typography variant="caption" color="text.primary" component="p">
            <strong>From: </strong>
            {email.senderName ? (
              <>
                {email.senderName} &lt;
                <Link
                  href={`mailto: ${email.senderEmail}`}
                  color="text.primary"
                  underline="always"
                >
                  {wrapEmailAccount(email.senderEmail)}
                </Link>
                &gt;
              </>
            ) : (
              <>{email.senderEmail}</>
            )}
          </Typography>
          <Typography variant="caption" color="text.primary" component="p">
            <strong>Subject: </strong>
            {email.subject}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        <HtmlEmbed email={email} />
      </Box>
    </Stack>
  );
}
