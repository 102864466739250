import { ReactElement, ReactNode } from "react";
import {
  Avatar,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DialogTitleHandlers {
  onClose?: () => void;
}
export type DialogTitleProps = Readonly<
  (
    | {
        loading?: false;
        primary: ReactNode;
        secondary?: ReactNode;
        avatar?: ReactNode;
      }
    | { loading: true; primary?: true; secondary: boolean; avatar: boolean }
  ) &
    DialogTitleHandlers &
    MuiDialogTitleProps
>;
export default function DialogTitle({
  loading,
  primary,
  secondary,
  avatar,
  onClose,
  ...dialogTitleProps
}: DialogTitleProps): ReactElement {
  return (
    <MuiDialogTitle {...dialogTitleProps}>
      <Stack direction="row" alignItems="center" spacing={3}>
        {avatar &&
          (loading ? (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          ) : (
            <Avatar>{avatar}</Avatar>
          ))}
        <Stack
          sx={{ flexGrow: 1, overflow: "hidden" /* allow ellipsis inside */ }}
        >
          <Typography variant="h6">
            {loading ? <Skeleton /> : primary}
          </Typography>
          {secondary && (
            <>
              <Typography
                variant="caption"
                paragraph
                sx={{ display: "flex", m: 0 }}
              >
                {loading ? <Skeleton /> : secondary}
              </Typography>
            </>
          )}
        </Stack>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </MuiDialogTitle>
  );
}
