import { ReactElement, useEffect } from "react";
import ViewDialogContainer from "containers/emailFlows/viewDialog/ViewDialogContainer";
import { useNavigate, useParams } from "react-router-dom";
import ResourceIdentifier from "@unkover/unkover-api-sdk/dist/ResourceIdentifier";
import useAlert from "hooks/useAlert";
import { debounce } from "@mui/material";
import { EmailsProvider } from "containers/emailFlows/contexts/EmailsContext";
import EmailFlow from "@unkover/unkover-api-sdk/dist/Model/EmailFlow/EmailFlow";
import { paths } from "routes/paths";

export default function ViewDialog(): ReactElement {
  const { emailFlow: emailFlowIdParam } = useParams();
  const navigate = useNavigate();
  const { error } = useAlert();

  const emailFlowId = Number(emailFlowIdParam);

  useEffect(() => {
    let isMounted = true;
    debounce(() => {
      if (isMounted && isNaN(emailFlowId)) {
        error("Email flow not found!");
        navigate(String(paths.home));
      }
    }, 100)();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailFlowId]);

  return (
    <>
      {!isNaN(emailFlowId) && (
        <EmailsProvider
          emailFlow={new ResourceIdentifier<EmailFlow>(emailFlowId)}
        >
          <ViewDialogContainer
            onClose={() => {
              navigate(String(paths.home));
            }}
          />
        </EmailsProvider>
      )}
    </>
  );
}
