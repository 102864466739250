import TimelineItem from "components/emailFlows/timeline/TimelineItem";
import { Fragment, ReactElement } from "react";
import { TimelineItemSelection } from "components/emailFlows/timeline/Timeline";
import TimelineGroupItem from "components/dialog/timeline/TimelineGroupItem";
import { Email } from "@unkover/unkover-api-sdk/dist/Model/Email";

type TimelineItemsProps = Readonly<{
  emails: Email[];
  selected?: TimelineItemSelection;
  onSelected: (selection: TimelineItemSelection) => void;
}>;
export default function TimelineItems({
  emails,
  selected,
  onSelected,
}: TimelineItemsProps): ReactElement {
  let previousYearForGrouping: number | undefined =
    emails[0]?.createdAt.getFullYear();

  const isSelected = (version: Email): boolean =>
    selected?.getIdentifier().id === version.getIdentifier().id;

  const handleSelection = (selected: Email): void => {
    onSelected(selected);
  };

  return (
    <>
      {emails.map((email, index) => {
        const isYearDifferent =
          previousYearForGrouping !== email.createdAt.getFullYear();
        previousYearForGrouping = email.createdAt.getFullYear();

        return (
          <Fragment key={index}>
            {isYearDifferent && (
              <TimelineGroupItem>
                {email.createdAt.getFullYear()}
              </TimelineGroupItem>
            )}
            <TimelineItem
              selectable
              email={email}
              selected={isSelected(email)}
              onClick={() => {
                handleSelection(email);
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
}
