import { Box, BoxProps, Divider, Stack } from "@mui/material";
import { dialogClasses } from "components/dialog/timeline/dialogClasses";
import { ReactElement } from "react";
import clsx from "clsx";

type DialogTimelineWrapperProps = Readonly<BoxProps>;
export default function DialogTimelineWrapper({
  children,
  ...boxProps
}: DialogTimelineWrapperProps): ReactElement {
  return (
    <Box
      {...boxProps}
      className={clsx(dialogClasses.dialogTimelineWrapper, boxProps.className)}
    >
      <Stack
        divider={<Divider flexItem orientation="horizontal" />}
        maxHeight="100%"
      >
        {children}
      </Stack>
    </Box>
  );
}
