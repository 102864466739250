import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import EmailFlow from "@unkover/unkover-api-sdk/dist/Model/EmailFlow/EmailFlow";
import Page from "@unkover/unkover-api-sdk/dist/Model/Page/Page";
import Resource from "@unkover/unkover-api-sdk/dist/Resource";
import ResourceIdentifier from "@unkover/unkover-api-sdk/dist/ResourceIdentifier";
import { generatePath } from "react-router";

export class Path<
  P extends
    | Record<string, string | Identifiable<Resource>>
    | undefined = undefined,
> {
  constructor(public path: string) {}

  resolve(params?: P) {
    for (const key in params) {
      params[key] = this.resolveValue(params[key as keyof typeof params]);
    }

    return generatePath(this.path, params);
  }

  toString() {
    return this.path;
  }

  private resolveValue(value: string | Identifiable<Resource>): string {
    if (value instanceof ResourceIdentifier || value instanceof Resource) {
      return String(value.getIdentifier());
    }

    return String(value);
  }
}

export const paths = {
  home: new Path("/"),

  login: new Path("/login"),
  logout: new Path("/logout"),
  forgotPassword: new Path("/forgot-password"),
  register: new Path("/onboarding"),
  registerSuccess: new Path("/onboarding/success"),
  configureAccount: new Path("/onboarding/configure"),
  otpVerify: new Path("/otp-verify"),
  impersonate: new Path<{ email: string; redirectTo?: string }>(
    "/impersonate/:email/:redirectTo?",
  ),

  pageDiff: new Path<{ page: Page }>("/page/:page/diff"),
  emailFlowView: new Path<{ emailFlow: EmailFlow }>(
    "/email-flow/:emailFlow/view",
  ),

  slackAuth: new Path("/slack/authorize"),
};
