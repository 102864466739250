import { Box, styled } from "@mui/material";

export const SliderHandler = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "var(--exposure, 50%)",
  transform: "translate(-50%, -50%)",
  cursor: "ew-resize",
  zIndex: 9999,
}));
