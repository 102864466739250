import StorageInterface from "services/auth/storage/StorageInterface";
import Cookies from "js-cookie";

export default class CookieStorage implements StorageInterface {
  private readonly cookies: Cookies.CookiesStatic;
  constructor() {
    this.cookies = Cookies.withAttributes({
      secure: import.meta.env.MODE === "production",
      sameSite: "strict",
    });
  }

  get(key: string, defaultValue?: string | undefined): string | null {
    return this.cookies.get(key) ?? defaultValue ?? null;
  }
  set(key: string, value: string): void {
    this.cookies.set(key, value);
  }
  delete(key: string): void {
    this.cookies.remove(key);
  }
  clear(): void {
    throw new Error("Method cannot be implemented.");
  }
  has(key: string): boolean {
    return this.cookies.get(key) !== undefined;
  }
}
