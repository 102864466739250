import { ComponentProps, ReactElement, useMemo } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import Company from "@unkover/unkover-api-sdk/dist/Model/Company/Company";
import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";

type Domain = string;

function getAvatarUrlFromGoogle(domain: Domain, size: number): string {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=${size}`;
}

function getCompanyAvatarUrl(company: Identifiable<Company>): string {
  return `${import.meta.env.REACT_APP_CDN_URL}/companies/${
    company.getIdentifier().id
  }/avatar`;
}

function extractAvatarFrom(
  company: Identifiable<Company> | Domain,
  size: number,
): string | null {
  if (typeof company === "string") {
    return getAvatarUrlFromGoogle(company, size);
  }

  return getCompanyAvatarUrl(company);
}

type CompanyAvatarProps = Readonly<
  {
    company: Identifiable<Company> | Domain;
    size?: number;
  } & ComponentProps<"img">
>;
export function CompanyAvatar({
  company,
  size = 36,
  ...imgProps
}: CompanyAvatarProps): ReactElement {
  const avatarUrl = useMemo(
    () => extractAvatarFrom(company, size),
    [company, size],
  );

  return avatarUrl ? (
    <img
      src={avatarUrl}
      alt={
        company instanceof Company
          ? company.name
          : typeof company === "string"
            ? company
            : undefined
      }
      height={size}
      width={size}
      {...imgProps}
    />
  ) : (
    <LanguageIcon sx={{ width: size, height: size }} />
  );
}
