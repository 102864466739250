import * as React from "react";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "contexts/AuthContext";
import { UserProvider } from "contexts/UserContext";
import Spinner from "components/spinner/Spinner";
import { LimitsProvider } from "contexts/LimitsContext";
import { AlertProvider } from "contexts/AlertContext";
import authenticator, { impersonator } from "services/auth";
import posthog from "lib/posthog";
import { PostHogProvider } from "posthog-js/react";
import * as Sentry from "@sentry/react";
import UnathorizedErrorBoundary from "components/error/UnauthorizedErrorBoundary";
import { EventListenersProvider } from "contexts/EventListenersContext";
import dispatcher from "services/events";
import { ImpersonateProvider } from "contexts/ImpersonateContext";
import { SubscriptionsProvider } from "contexts/SubscriptionsContext";
import GeneralError from "components/error/GeneralError";
import { LocalizationProvider } from "contexts/LocalizationContext";
import { OwnedCompanyProvider } from "contexts/OwnedCompanyContext";
import TrackPageView from "containers/tracking/TrackPageView";
import ImpersonatingWarningBar from "containers/auth/ImpersonatingWarningBar";
import HighlightedPromoAlertBar from "containers/payment/HighlightedPromoAlertBar";
import StickyBox from "components/sticky/StickyBox";

export default function App() {
  return (
    <EventListenersProvider dispatcher={dispatcher}>
      <AlertProvider>
        <AuthProvider authenticator={authenticator}>
          <ImpersonateProvider impersonator={impersonator}>
            <Sentry.ErrorBoundary fallback={<GeneralError fullScreen />}>
              <UnathorizedErrorBoundary>
                <UserProvider fallback={<Spinner fullScreen />}>
                  <LocalizationProvider>
                    <SubscriptionsProvider>
                      <LimitsProvider>
                        <OwnedCompanyProvider>
                          <PostHogProvider client={posthog}>
                            <TrackPageView>
                              <StickyBox>
                                <ImpersonatingWarningBar />
                                <HighlightedPromoAlertBar />
                              </StickyBox>
                              <Outlet />
                            </TrackPageView>
                          </PostHogProvider>
                        </OwnedCompanyProvider>
                      </LimitsProvider>
                    </SubscriptionsProvider>
                  </LocalizationProvider>
                </UserProvider>
              </UnathorizedErrorBoundary>
            </Sentry.ErrorBoundary>
          </ImpersonateProvider>
        </AuthProvider>
      </AlertProvider>
    </EventListenersProvider>
  );
}
