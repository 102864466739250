// ** Util Import
import { hexToRGBA } from "utils/hex-to-rgba";
import { Components, Theme } from "@mui/material";

const Timeline: Partial<Components<Theme>> = {
  MuiTimelineItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        "&:not(:last-of-type)": {
          "& .MuiTimelineContent-root": {
            marginBottom: theme.spacing(0),
          },
        },
      }),
    },
  },
  MuiTimelineConnector: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.divider,
      }),
    },
  },
  MuiTimelineContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginTop: theme.spacing(0.5),
      }),
    },
  },
  MuiTimelineDot: {
    styleOverrides: {
      filledPrimary: ({ theme }) => ({
        boxShadow: `0 0 0 3px ${hexToRGBA(theme.palette.primary.main, 0.12)}`,
      }),
      filledSecondary: ({ theme }) => ({
        boxShadow: `0 0 0 3px ${hexToRGBA(theme.palette.secondary.main, 0.12)}`,
      }),
      filledGrey: ({ theme }) => ({
        boxShadow: `0 0 0 3px ${hexToRGBA(theme.palette.grey[400], 0.12)}`,
      }),
      outlinedPrimary: ({ theme }) => ({
        "& svg": { color: theme.palette.primary.main },
      }),
      outlinedSecondary: ({ theme }) => ({
        "& svg": { color: theme.palette.secondary.main },
      }),
      outlinedGrey: ({ theme }) => ({
        "& svg": { color: theme.palette.grey[400] },
      }),
    },
  },
};

export default Timeline;
