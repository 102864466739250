import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import { AccessToken } from "services/auth";

export default class Created extends Event {
  public static type = "user.created";

  constructor(
    public readonly user: User,
    public readonly accessToken: AccessToken,
  ) {
    super(Created.type);
  }
}
