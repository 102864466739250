import Created from "events/user/Created";
import {
  tracker,
  segmentTracker,
  gaTracker,
  gtmTracker,
} from "services/analytics";
import { Listener } from "listeners/types";
import UserDepartment from "locale/UserDepartment";

const trackUserCreated: Listener<Created> = (event) => {
  tracker.identify(event.user);

  const properties = {
    email: event.user.email,
    first_name: event.user.firstName,
    last_name: event.user.lastName,
    department: !event.user.department
      ? null
      : UserDepartment[event.user.department] ?? null,
    company_website: event.user.websiteUrl,
  };

  // FROM BE
  // segmentTracker.track("User Created", properties);

  gaTracker?.track("Account_Creation", properties);
  gtmTracker?.track("registration", properties);
};

export default trackUserCreated;
