import type { Listeners } from "listeners/types";

export default class Dispatcher {
  private listeners: Listeners = {};

  constructor(listeners?: Listeners) {
    this.listeners = listeners ?? {};
  }

  public dispatch(event: Event): void {
    const listeners = this.listeners[event.type];
    if (!listeners) {
      return;
    }

    listeners.forEach((listener) => {
      listener(event);
    });
  }
}
