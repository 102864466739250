import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import TrackerInterface, {
  PageParams,
} from "services/analytics/TrackerInterface";
import { AnalyticsBrowser } from "@segment/analytics-next";
import AnonymousUser from "services/analytics/AnonymousUser";
import ImpersonatedUser from "@unkover/unkover-api-sdk/dist/Model/User/ImpersonatedUser";
import { DispatchedEvent } from "@segment/analytics-next/dist/types/core/arguments-resolver";

export default class SegmentTracker implements TrackerInterface {
  private identifiedUser: User | AnonymousUser | null = null;
  private anonymousEventsQueue: DispatchedEvent[] = [];

  constructor(private readonly analytics: AnalyticsBrowser) {}

  identify(user: User | AnonymousUser): void {
    this.identifiedUser = user;

    if (user instanceof AnonymousUser) {
      void this.analytics.identify({
        email: user.email,
      });

      return;
    }

    void this.analytics.identify(String(user.getIdentifier()), {
      email: user.email,
      name: user.getFullName(),
    });
  }

  track(event: string, properties?: Record<string, unknown>): void {
    void this.analytics.track(
      event,
      this.decoratePropertiesWithUser(properties ?? {}),
    );
  }

  page(params?: PageParams): void {
    const properties: Record<string, unknown> = {};

    if (params?.path) {
      properties.path = params.path;
      properties.url = `${window.location.origin}${params.path}`;
    }

    if (params?.title) {
      properties.title = params.title;
    }

    if (params?.referrer) {
      properties.referrer = params.referrer.toString();
    }

    void this.analytics.page(
      params?.category,
      params?.name,
      this.decoratePropertiesWithUser(properties),
    );
  }

  reset(): void {
    void this.analytics.reset();
  }

  private decoratePropertiesWithUser(properties: Record<string, unknown>) {
    if (this.identifiedUser instanceof ImpersonatedUser) {
      properties.impersonatedBy =
        this.identifiedUser.impersonator.getIdentifier().id;
    }

    return properties;
  }
}
