import { ReactElement } from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import BaseTimelineItem, {
  TimelineItemProps as BaseTimelineItemProps,
} from "components/dialog/timeline/TimelineItem";
import HumanDurationFromNow from "components/date/HumanDurationFromNow";
import TimelineItemImage from "components/dialog/timeline/TimelineItemImage";
import TimelineItemTitle from "components/dialog/timeline/TimelineItemTitle";
import { Email } from "@unkover/unkover-api-sdk/dist/Model/Email";
import TimelineItemDescription from "components/dialog/timeline/TimelineItemDescription";

type TimelineItemProps = Readonly<
  {
    email: Email;
  } & BaseTimelineItemProps
>;
export default function TimelineItem({
  email,
  ...itemProps
}: TimelineItemProps): ReactElement {
  return (
    <BaseTimelineItem {...itemProps}>
      <TimelineSeparator>
        <TimelineDot>
          <EmailOutlined fontSize="xsmall" />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction="column" spacing={2}>
          <TimelineItemTitle>
            <HumanDurationFromNow date={email.createdAt} />
          </TimelineItemTitle>
          <TimelineItemDescription>
            <Typography variant="caption" paragraph>
              {email.subject}
            </Typography>
          </TimelineItemDescription>
          <TimelineItemImage src={email.image} width={200} height={132} />
        </Stack>
      </TimelineContent>
    </BaseTimelineItem>
  );
}
