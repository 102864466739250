import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import AccessToken from "@unkover/unkover-api-sdk/dist/Model/Auth/AccessToken";
import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import Authenticator from "services/auth/authenticator/Authenticator";
import ImpersonateRequestStorage from "services/auth/authenticator/storage/ImpersonateRequestStorage";
import ImpersonatorStorage from "services/auth/authenticator/storage/ImpersonatorStorage";

type ImpersonableUser = Identifiable<User> | string;

export default class Impersonator {
  constructor(
    protected readonly authenticator: Authenticator,
    protected readonly impersonatorTokenStorage: ImpersonatorStorage,
    protected readonly impersonatedRequestStorage: ImpersonateRequestStorage,
  ) {}

  public getImpersonatorToken(): AccessToken | null {
    return this.impersonatorTokenStorage.get();
  }

  public async impersonate(
    toImpersonate: ImpersonableUser,
    impersonator: User,
  ): Promise<[AccessToken, AccessToken]> {
    const impersonatorToken =
      this.getImpersonatorToken() ?? this.authenticator.getAccessToken();
    if (null === impersonatorToken) {
      throw new Error(
        "Current user must be authenticated before impersonating",
      );
    }

    const impersonatedToken = await impersonator.impersonate(toImpersonate);
    this.impersonatorTokenStorage.store(impersonatorToken);
    this.impersonatedRequestStorage.store(toImpersonate);

    return [impersonatedToken, impersonatorToken];
  }

  public stopImpersonating(): void {
    this.impersonatorTokenStorage.clear();
    this.impersonatedRequestStorage.clear();
  }

  public isImpersonating(): boolean {
    return this.getImpersonatorToken() !== null;
  }

  public getInitialRequest(): ImpersonableUser | null {
    return this.impersonatedRequestStorage.get();
  }
}
