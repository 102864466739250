import { ReactElement, useState } from "react";
import ErrorBoundary from "components/error/ErrorBoundary";
import GeneralErrorAlert from "components/error/GeneralErrorAlert";
import Spinner from "components/spinner/Spinner";
import DialogTitle from "containers/emailFlows/viewDialog/DialogTitle";
import DialogContent from "containers/emailFlows/viewDialog/DialogContent";
import Dialog from "components/dialog/timeline/Dialog";
import useEmails from "containers/emailFlows/hooks/useEmails";

type ViewDialogInternalProps = Readonly<{
  onClose?: () => void;
}>;
function ViewDialogInternal({
  onClose,
}: ViewDialogInternalProps): ReactElement {
  const { emails, isFetchingPage } = useEmails();

  if (emails.length === 0 && isFetchingPage) {
    return <Spinner />;
  }

  return (
    <>
      <DialogTitle onClose={onClose} sx={{ py: 3 }} />
      <DialogContent dividers />
    </>
  );
}

type ViewDialogContainerProps = Readonly<{
  onClose?: () => void;
}>;
export default function ViewDialogContainer({
  onClose,
}: ViewDialogContainerProps): ReactElement {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ErrorBoundary
        fallback={(reset) => <GeneralErrorAlert resetError={reset} />}
      >
        <ViewDialogInternal onClose={handleClose} />
      </ErrorBoundary>
    </Dialog>
  );
}
