import { Components, Theme } from "@mui/material";

const paper: Partial<Components<Theme>> = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
};

export default paper;
