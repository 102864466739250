import { Components, Theme } from "@mui/material";

const select: Partial<Components<Theme>> = {
  MuiSelect: {
    defaultProps: {
      variant: "filled",
    },
    styleOverrides: {
      select: {
        minWidth: "6rem !important",
        "&.MuiTablePagination-select": {
          minWidth: "1.5rem !important",
        },
      },
    },
  },
};

export default select;
