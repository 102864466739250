import { Components, Theme } from "@mui/material";

const Typography: Partial<Components<Theme>> = {
  MuiTypography: {
    styleOverrides: {
      gutterBottom: ({ theme }) => ({
        marginBottom: theme.spacing(3),
      }),
    },
  },
};

export default Typography;
