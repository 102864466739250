import { AxiosError, AxiosInstance } from "axios";
import InvalidOtpCode from "services/auth/authenticator/errors/InvalidOtpCode";
import OptCodeExpired from "services/auth/authenticator/errors/OptCodeExpired";

export default class OtpVerifier {
  constructor(protected readonly _client: AxiosInstance) {}

  public async verifyEmailOpt(otp: string): Promise<void> {
    try {
      await this._client.post("/email-otp-verify", { otp }); return;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 422) {
        throw new OptCodeExpired(error);
      }

      if (error instanceof AxiosError && error.response?.status === 404) {
        throw new InvalidOtpCode(error);
      }

      throw error;
    }
  }

  public async resendEmailOpt(): Promise<void> {
    await this._client.post("/email-otp-resend");
  }
}
