import { ReactElement, useState } from "react";
import ErrorBoundary from "components/error/ErrorBoundary";
import GeneralErrorAlert from "components/error/GeneralErrorAlert";
import Spinner from "components/spinner/Spinner";
import DialogTitle from "containers/pageDiff/DialogTitle";
import DialogContent from "containers/pageDiff/DialogContent";
import usePageVersions from "containers/pageDiff/hooks/usePageVersions";
import Dialog from "components/dialog/timeline/Dialog";

type PageDiffDialogInternalProps = Readonly<{
  onClose?: () => void;
}>;
function PageDiffDialogInternal({
  onClose,
}: PageDiffDialogInternalProps): ReactElement {
  const { versions, isFetchingPage } = usePageVersions();

  if (versions.length === 0 && isFetchingPage) {
    return <Spinner />;
  }

  return (
    <>
      <DialogTitle onClose={onClose} sx={{ py: 3 }} />
      <DialogContent dividers />
    </>
  );
}

type PageDiffDialogContainerProps = Readonly<{
  onClose?: () => void;
}>;
export default function PageDiffDialogContainer({
  onClose,
}: PageDiffDialogContainerProps): ReactElement {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <ErrorBoundary
        fallback={(reset) => <GeneralErrorAlert resetError={reset} />}
      >
        <PageDiffDialogInternal onClose={handleClose} />
      </ErrorBoundary>
    </Dialog>
  );
}
