import { EventListenersContext } from "contexts/EventListenersContext";
import { useContext } from "react";

export default function useDispatcher() {
  const context = useContext(EventListenersContext);

  if (!context) {
    throw new Error(
      "useDispatcher must be used within an EventListenersProvider",
    );
  }

  return context;
}
