// ** Util Import
import { hexToRGBA } from "utils/hex-to-rgba";
import { Components, Theme, alpha } from "@mui/material";

const Backdrop: Partial<Components<Theme>> = {
  MuiBackdrop: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor:
          theme.palette.mode === "light"
            ? alpha(theme.palette.customColors.main, 0.7)
            : hexToRGBA(theme.palette.background.default, 0.7),
      }),
      invisible: {
        backgroundColor: "transparent",
      },
    },
  },
};

export default Backdrop;
