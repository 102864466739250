import { ReactElement, useEffect } from "react";
import PageDiffDialogContainer from "containers/pageDiff/PageDiffDialogContainer";
import { useNavigate, useParams } from "react-router-dom";
import ResourceIdentifier from "@unkover/unkover-api-sdk/dist/ResourceIdentifier";
import Page from "@unkover/unkover-api-sdk/dist/Model/Page/Page";
import useAlert from "hooks/useAlert";
import { debounce } from "@mui/material";
import { PageVersionsProvider } from "containers/pageDiff/contexts/PageVersionsContext";
import { paths } from "routes/paths";

export default function PageDiffDialog(): ReactElement {
  const { page: pageIdParam } = useParams();
  const navigate = useNavigate();
  const { error } = useAlert();

  const pageId = Number(pageIdParam);

  useEffect(() => {
    let isMounted = true;
    debounce(() => {
      if (isMounted && isNaN(pageId)) {
        error("Page not found!");
        navigate(String(paths.home));
      }
    }, 100)();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  return (
    <>
      {!isNaN(pageId) && (
        <PageVersionsProvider page={new ResourceIdentifier<Page>(pageId)}>
          <PageDiffDialogContainer
            onClose={() => {
              navigate(String(paths.home));
            }}
          />
        </PageVersionsProvider>
      )}
    </>
  );
}
