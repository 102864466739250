import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";

type GeneralErrorProps = Readonly<{
  fullScreen?: boolean;
  message?: React.ReactNode;
  description?: React.ReactNode;
}>;
export default function GeneralError({
  message,
  description,
  fullScreen: fullscreen = false,
}: GeneralErrorProps): ReactElement {
  return (
    <Box
      sx={{
        height: fullscreen ? "100vh" : undefined,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h3">
        {message ?? "Ops, something went wrong"}
      </Typography>
      {description && <Typography variant="body1">{description}</Typography>}
    </Box>
  );
}
