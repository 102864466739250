import { Components, Theme } from "@mui/material";

const Menu: Partial<Components<Theme>> = {
  MuiMenu: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiMenu-paper": {
          borderRadius: 5,
          boxShadow: theme.shadows[8],
        },
      }),
    },
  },
};

export default Menu;
