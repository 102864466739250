import AccessToken from "@unkover/unkover-api-sdk/dist/Model/Auth/AccessToken";
import { StorageInterface } from "services/auth/storage";

export default class ImpersonatorStorage {
  private KEY = "impersonatorToken";

  constructor(
    protected readonly _storage: StorageInterface,
    protected readonly _deprecatedStorage?: StorageInterface,
  ) {}

  public get(): AccessToken | null {
    this._migrateDeprecatedStorage();

    const token = this._storage.get(this.KEY);
    if (token === null) {
      return null;
    }

    return new AccessToken(token);
  }

  public store(token: AccessToken): AccessToken {
    this._storage.set(this.KEY, String(token));

    return token;
  }

  public clear = () => {
    this._storage.delete(this.KEY);
  };

  private _migrateDeprecatedStorage(): void {
    if (this._deprecatedStorage === undefined) {
      return;
    }

    if (this._deprecatedStorage.has(this.KEY)) {
      this.store(new AccessToken(this._deprecatedStorage.get(this.KEY) ?? ""));

      this._deprecatedStorage.delete(this.KEY);
    }
  }
}
