import { ReactElement } from "react";
import usePageVersions from "containers/pageDiff/hooks/usePageVersions";
import ExternalLink from "components/link/ExternalLink";
import BaseDialogTitle, {
  DialogTitleProps as BaseDialogTitleProps,
} from "components/dialog/timeline/DialogTitle";
import { CompanyAvatar } from "components/company/Avatar";

type DialogTitleProps = Readonly<
  {
    onClose?: () => void;
  } & Omit<BaseDialogTitleProps, "loading">
>;
export default function DialogTitle({
  onClose,
  ...dialogTitleProps
}: DialogTitleProps): ReactElement {
  const { versions, isFetchingPage } = usePageVersions();

  const lastVersion = versions[0];
  const notFound = !isFetchingPage && !lastVersion;

  const loading = !lastVersion && isFetchingPage;

  return (
    <>
      {loading && <BaseDialogTitle loading avatar secondary />}
      {!loading && !notFound && lastVersion && (
        <BaseDialogTitle
          {...dialogTitleProps}
          avatar={<CompanyAvatar company={lastVersion.company} size={40} />}
          primary={lastVersion.pageTitle}
          secondary={<ExternalLink href={lastVersion.pageUrl.toString()} />}
          onClose={onClose}
        />
      )}
    </>
  );
}
