import useAuth from "hooks/useAuth";
import { ElementType, ReactElement, ReactNode } from "react";
import { Navigate } from "react-router";
import { paths } from "routes/paths";

export type PublicRouteProps<P = Record<string, unknown>> = P & {
  redirectWhenAuthenticated?: boolean | string;
} & (
    | {
        children: ReactNode;
        page?: undefined;
      }
    | {
        children?: undefined;
        page: ElementType<P>;
      }
  );
export default function PublicRoute({
  children,
  page: PageContent,
  redirectWhenAuthenticated = false,
  ...pageContentProps
}: PublicRouteProps): ReactElement {
  const auth = useAuth();

  if (auth.isAuthenticated && redirectWhenAuthenticated) {
    const fallbackPath =
      "string" === typeof redirectWhenAuthenticated
        ? redirectWhenAuthenticated
        : paths.home.resolve();

    return <Navigate to={fallbackPath} replace />;
  }

  return undefined === PageContent ? (
    <>{children}</>
  ) : (
    <PageContent {...pageContentProps} />
  );
}
