import { ImpersonateContext } from "contexts/ImpersonateContext";
import { useContext } from "react";

export default function useImpersonate() {
  const context = useContext(ImpersonateContext);

  if (!context) {
    throw new Error("useImpersonate must be used within a ImpersonateProvider");
  }

  return context;
}
