import { Components, Theme } from "@mui/material";

const Popover: Partial<Components<Theme>> = {
  MuiPopover: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiPopover-paper": {
          boxShadow: theme.shadows[6],
        },
      }),
    },
  },
};

export default Popover;
