import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { ReactElement, useState } from "react";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { SessionIntent } from "@unkover/unkover-api-sdk/dist/Model/Payment/SessionIntent/SessionIntent";
import BillingPortal from "@unkover/unkover-api-sdk/dist/Model/Payment/BillingPortal";
import { useSdk } from "sdk";

function getReturnUrl(path: string): URL {
  const appUrl = `${window.location.protocol}//${window.location.host}`;

  return new URL(`${appUrl}${path}`);
}

type CustomerPortalButtonProps = LoadingButtonProps & {
  intent?: SessionIntent;
  returnUrl: URL | string;
};
export default function CustomerPortalButton({
  intent,
  children,
  returnUrl,
  ...props
}: CustomerPortalButtonProps): ReactElement {
  const client = useSdk({ nullableWhenNotAuth: true });
  const throwAsyncError = useThrowAsyncError();
  const [loading, setLoading] = useState(false);

  const handleClick: LoadingButtonProps["onClick"] = (e) => {
    if (null === client) {
      return;
    }

    props.onClick?.(e);
    setLoading(true);

    const resolvedReturnUrl =
      returnUrl instanceof URL ? returnUrl : getReturnUrl(returnUrl);

    new BillingPortal(client)
      .get(resolvedReturnUrl, intent)
      .then((portal) => {
        window.location.href = portal.url;
      })
      .catch((error) => {
        setLoading(false);
        throwAsyncError(error);
      });
  };

  return (
    <LoadingButton {...props} onClick={handleClick} loading={loading}>
      {children}
    </LoadingButton>
  );
}
