// ** Util Import
import { hexToRGBA } from "utils/hex-to-rgba";
import { Components, Theme } from "@mui/material";

const Tooltip: Partial<Components<Theme>> = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor:
          theme.palette.mode === "light"
            ? hexToRGBA(theme.palette.grey[900], 0.9)
            : hexToRGBA(theme.palette.grey[700], 0.9),
      }),
      arrow: ({ theme }) => ({
        color:
          theme.palette.mode === "light"
            ? hexToRGBA(theme.palette.grey[900], 0.9)
            : hexToRGBA(theme.palette.grey[700], 0.9),
      }),
    },
  },
};

export default Tooltip;
