import { Components, Theme, alpha } from "@mui/material";

// ** Hook Import
const Accordion: Partial<Components<Theme>> = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.shadows[1],
        "&.Mui-disabled": {
          backgroundColor: alpha(theme.palette.customColors.main, 0.12),
        },
        "&.Mui-expanded": {
          boxShadow: theme.shadows[3],
          "&:not(:first-of-type)": {
            borderTop: `1px solid ${theme.palette.divider}`,
          },
        },
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: "inherit",
        padding: `0 ${theme.spacing(5)}`,
        "&.Mui-expanded": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
        "& + .MuiCollapse-root": {
          "& .MuiAccordionDetails-root:first-child": {
            paddingTop: 0,
          },
        },
      }),
      content: ({ theme }) => ({
        margin: `${theme.spacing(2.5)} 0`,
      }),
      expandIconWrapper: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(5),
        "& + .MuiAccordionDetails-root": {
          paddingTop: 0,
        },
      }),
    },
  },
};

export default Accordion;
