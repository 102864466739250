import ImpersonatedUser from "@unkover/unkover-api-sdk/dist/Model/User/ImpersonatedUser";
import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import UnauthorizedError from "exceptions/UnauthorizedError";
import { getClient } from "sdk";
import { AccessToken, Authenticator } from "services/auth";
import Impersonator from "services/auth/authenticator/Impersonator";

export default class UserProvider {
  private _userResolvers: Record<string, Promise<User> | null> = {};

  constructor(
    private readonly authenticator: Authenticator,
    private readonly impersonator: Impersonator,
  ) {}

  async getUser(): Promise<User | null> {
    const accessToken = this.authenticator.getAccessToken();
    if (null === accessToken) {
      this.resetUser();
      return null;
    }

    let resolver = this._userResolvers[String(accessToken)];
    if (undefined === resolver) {
      resolver = (await this.buildUserInstance(accessToken)).get();
      this._userResolvers = {
        ...this._userResolvers,
        [String(accessToken)]: resolver,
      };
    }

    try {
      const user = await resolver;
      if (!this.authenticator.isAuthenticated()) {
        this.resetUser();
        return null;
      }

      return user;
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        this.resetUser();
        return null;
      }

      throw e;
    }
  }

  resetUser(): void {
    this._userResolvers = {};
  }

  private async buildUserInstance(accessToken: AccessToken): Promise<User> {
    const impersonatorToken = this.impersonator.getImpersonatorToken();
    if (null !== impersonatorToken) {
      const impersonator = await new User(getClient(impersonatorToken)).get();
      return new ImpersonatedUser(impersonator, getClient(accessToken));
    }

    return new User(getClient(accessToken));
  }
}
