import {
  Components,
  Theme,
  alpha,
  autocompleteClasses,
  filledInputClasses,
  inputBaseClasses,
} from "@mui/material";

const input: Partial<Components<Theme>> = {
  MuiTextField: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.others.fieldBg,
        border: `1px solid ${theme.palette.others.fieldBorder}`,
        borderRadius: theme.shape.borderRadius,
        "&:before, &:after": {
          display: "none",
        },
        [`& .${inputBaseClasses.input}`]: {
          padding: "24px 12px 7px",
        },
        [`&.${autocompleteClasses.inputRoot}`]: {
          paddingTop: "16px",
        },

        "&:hover": {
          backgroundColor: theme.palette.others.fieldBg,
        },
        [`&.${filledInputClasses.focused}`]: {
          backgroundColor: theme.palette.others.fieldBg,
          borderColor: theme.palette.primary.main,
        },
        [`&.${filledInputClasses.disabled}`]: {
          backgroundColor: theme.palette.others.fieldBg,
          borderColor: theme.palette.others.fieldBorder,
        },
        [`&.${filledInputClasses.error}`]: {
          borderColor: theme.palette.error.main,
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        "&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: alpha(theme.palette.customColors.main, 0.32),
          },
        "&:hover.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: alpha(theme.palette.customColors.main, 0.32),
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.text.disabled,
        },
      }),
    },
  },
};

export default input;
