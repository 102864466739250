import { Components, Theme } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const Rating: Partial<Components<Theme>> = {
  MuiRating: {
    defaultProps: {
      emptyIcon: <StarIcon fontSize="inherit" />,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.rating.main,
        "& svg": {
          flexShrink: 0,
        },
      }),
      iconEmpty: ({ theme }) => ({
        color: theme.palette.divider,
      }),
    },
  },
};

export default Rating;
