import Added from "events/competitor/Added";
import { segmentTracker, gaTracker } from "services/analytics";
import { Listener } from "listeners/types";

const trackCompetitorAdded: Listener<Added> = (event) => {
  const properties = {
    competitor_id: String(event.competitor.getIdentifier().id),
    competitor_name: event.competitor.company.name,
  };

  // FROM BE
  // segmentTracker.track("Competitor Added", properties);

  gaTracker?.track("Competitor_Added", properties);
};

export default trackCompetitorAdded;
