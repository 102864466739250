import PageView from "events/PageView";
import { paths } from "routes/paths";
import { segmentTracker } from "services/analytics";
import { Listener } from "listeners/types";

const trackEmailFlowAsEvent: Listener<PageView> = (event) => {
  const matchedRoute = event.matchedRoutes?.at(-1);
  if (matchedRoute?.route.path !== String(paths.emailFlowView)) {
    return;
  }

  segmentTracker.track("Email Viewed");
};

export default trackEmailFlowAsEvent;
