import { ReactElement, forwardRef } from "react";
import {
  DialogProps as MuiDialogProps,
  avatarClasses,
  dialogTitleClasses,
  styled,
} from "@mui/material";
import { Dialog as MuiDialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { dialogClasses } from "components/dialog/timeline/dialogClasses";
import { timelineClasses } from "@mui/lab";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const BaseDialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: { minHeight: "90vh", maxHeight: "90vh" },

  [`& .${dialogTitleClasses.root}`]: {
    [`& .${avatarClasses.root}`]: {
      border: `2px solid ${theme.palette.divider}`,
    },
  },

  [`& .${dialogClasses.dialogContentWrapper}`]: {
    display: "flex",
    padding: 0,

    [`& .${dialogClasses.dialogMainContent}`]: {
      backgroundColor: theme.palette.primary.lightest,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      display: "flex",
      width: "100%",
      flexGrow: 1,
      overflowY: "scroll",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },

    [`& .${dialogClasses.dialogTimelineWrapper}`]: {
      [`& .${dialogClasses.dialogTimelineTitle}`]: {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
      },
      [`& .${timelineClasses.root}`]: {
        overflowY: "scroll",
        overflowX: "hidden",
      },
    },

    "& iframe": {
      width: "100%",
      height: "100%",
      border: "none",
    },
  },
}));

type DialogProps = MuiDialogProps;
export default function Dialog({
  children,
  ...dialogProps
}: DialogProps): ReactElement {
  return (
    <BaseDialog
      fullWidth
      maxWidth="xl"
      TransitionComponent={Transition}
      keepMounted
      {...dialogProps}
    >
      {children}
    </BaseDialog>
  );
}
