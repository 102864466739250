import {
  CheckOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  LightbulbOutlined,
  LocalOfferOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import {
  SxProps,
  Alert,
  typographyClasses,
  alertClasses,
  AlertProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";

interface TopbarAlertProps
  extends Pick<AlertProps, "children" | "action" | "elevation"> {
  variant: "info" | "warning" | "error" | "success" | "promo" | "tip";
  sx?: SxProps<Theme>;
  disabledTypography?: boolean;
}
export default function TopbarAlert({
  variant,
  sx,
  children,
  action,
  elevation = 6,
  disabledTypography = false,
}: TopbarAlertProps): ReactElement {
  const severityMap: Record<
    TopbarAlertProps["variant"],
    AlertProps["severity"]
  > = {
    info: "info",
    warning: "warning",
    error: "error",
    success: "success",
    promo: "info",
    tip: "info",
  };
  const iconMap: Record<TopbarAlertProps["variant"], ReactElement> = {
    info: <InfoOutlined fontSize="inherit" />,
    success: <CheckOutlined fontSize="inherit" />,
    warning: <WarningAmberOutlined fontSize="inherit" />,
    error: <ErrorOutlineOutlined fontSize="inherit" />,
    promo: <LocalOfferOutlined fontSize="inherit" />,
    tip: <LightbulbOutlined fontSize="inherit" />,
  };

  return (
    <Alert
      severity={severityMap[variant]}
      icon={iconMap[variant] ?? false}
      elevation={elevation}
      action={action}
      sx={[
        (theme) => ({
          borderRadius: 0,
          justifyContent: "center",
          alignItems: "center",
          [`& .${alertClasses.icon}`]: {
            borderRadius: "100px",
            padding: theme.spacing(2),
          },
          [`& .${alertClasses.action}`]: {
            margin: 0,
          },
        }),
        variant === "promo" && {
          backgroundColor: (theme) => theme.palette.promo.main,
          [`& .${typographyClasses.root}`]: {
            color: (theme) => theme.palette.promo.contrastText,
          },
          [`& .${alertClasses.icon}`]: {
            backgroundColor: (theme) => theme.palette.promo.light,
            color: (theme) => theme.palette.promo.contrastText,
          },
        },
        variant === "tip" && {
          backgroundColor: (theme) => theme.palette.tip.main,
          [`& .${typographyClasses.root}`]: {
            color: (theme) => theme.palette.tip.contrastText,
          },
          [`& .${alertClasses.icon}`]: {
            backgroundColor: (theme) => theme.palette.tip.light,
            color: (theme) => theme.palette.tip.contrastText,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {disabledTypography ? (
        <>{children}</>
      ) : (
        <Typography variant="body2">{children}</Typography>
      )}
    </Alert>
  );
}
