import { Box, typographyClasses } from "@mui/material";
import { timelineItemClasses } from "components/dialog/timeline/timelineItemClasses";
import { ReactElement, ReactNode } from "react";

type TimelineItemDescriptionProps = Readonly<{ children: ReactNode }>;
export default function TimelineItemDescription({
  children,
}: TimelineItemDescriptionProps): ReactElement {
  return (
    <Box
      className={timelineItemClasses.itemDescription}
      sx={{ [`& .${typographyClasses.root}`]: { mb: 0 } }}
    >
      {children}
    </Box>
  );
}
