import { getAuthenticator, getImpersonator } from "./authenticator/builder";

export * from "./authenticator";
export * from "./register";
export * from "./storage";
export * from "./exceptions";

const authenticator = getAuthenticator();
export const impersonator = getImpersonator(authenticator);

export default authenticator;
