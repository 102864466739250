import useLocalization from "hooks/useLocalization";
import { ReactElement } from "react";

const variants: Record<string, Intl.DateTimeFormatOptions> = {
  short: { year: "numeric", month: "short", day: "numeric" },
  long: { year: "numeric", month: "long", day: "numeric" },
  medium: { dateStyle: "medium", timeStyle: "medium" },
  full: { dateStyle: "long", timeStyle: "long" },
};

interface FormattedDateProps {
  date: Date;
  variant?: keyof typeof variants;
  withoutYear?: boolean;
}
export default function FormattedDate({
  date,
  variant = "full",
  withoutYear = false,
}: FormattedDateProps): ReactElement {
  const { dateToLocaleString } = useLocalization();
  const options: Intl.DateTimeFormatOptions = {
    ...variants[variant],
    year: withoutYear ? undefined : variants[variant]?.year,
  };

  return (
    <time dateTime={date.toISOString()}>
      {dateToLocaleString(date, options)}
    </time>
  );
}
