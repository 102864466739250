import LoggedIn from "events/user/LoggedIn";
import LoggedOut from "events/user/LoggedOut";
import useAlert from "hooks/useAlert";
import useDispatcher from "hooks/useDispatcher";
import { ReactElement, createContext, useState } from "react";
import { AccessToken, Authenticator } from "services/auth";

export type AuthContextState = AuthenticatedState | UnauthenticatedState;

export interface AuthenticatedState {
  isAuthenticated: true;
  accessToken: AccessToken;
}

export interface UnauthenticatedState {
  isAuthenticated: false;
}
export interface AuthContextHandlers {
  login: (username: string, password: string) => Promise<void>;
  loginWithToken: (accessToken: AccessToken) => void;
  logout: (reason?: "unauthorized") => void;
}

export const AuthContext = createContext<
  (AuthContextState & AuthContextHandlers) | undefined
>(undefined);

export function AuthProvider({
  authenticator,
  children,
}: {
  authenticator: Authenticator;
  children: React.ReactNode;
}): ReactElement {
  const { dispatch } = useDispatcher();
  const { error } = useAlert();

  const accessToken = authenticator.getAccessToken();
  const [state, setState] = useState<AuthContextState>(
    accessToken
      ? { isAuthenticated: true, accessToken }
      : { isAuthenticated: false },
  );

  const login = async (username: string, password: string) => {
    const [accessToken, user] = await authenticator.authenticate(
      username,
      password,
    );
    setState({
      isAuthenticated: true,
      accessToken,
    });

    dispatch(new LoggedIn(user));
  };

  const loginWithToken = (accessToken: AccessToken) => {
    authenticator.authenticateWithToken(accessToken);
    setState({
      isAuthenticated: true,
      accessToken,
    });
  };

  const logout = (reason?: "unauthorized") => {
    authenticator.logout();
    setState({
      isAuthenticated: false,
    });

    if (reason === "unauthorized") {
      error("Unauthorized.");
    }

    dispatch(new LoggedOut());
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        loginWithToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
