import { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Alert,
  DialogContent as MuiDialogContent,
  Typography,
} from "@mui/material";
import BaseDialogContent, {
  DialogContentProps as BaseDialogContentProps,
} from "components/dialog/timeline/DialogContent";
import DialogMainContent from "components/dialog/timeline/DialogMainContent";
import DialogTimelineWrapper from "components/dialog/timeline/DialogTimelineWrapper";
import DialogTimelineTitle from "components/dialog/timeline/DialogTimelineTitle";
import useEmails from "containers/emailFlows/hooks/useEmails";
import { Email, EmailDetail } from "@unkover/unkover-api-sdk/dist/Model/Email";
import LoadingContent from "contexts/types/LoadingContent";
import Spinner from "components/spinner/Spinner";
import Timeline from "components/emailFlows/timeline/Timeline";
import EmailView from "components/emailFlows/view/EmailView";

type DialogContentProps = Readonly<BaseDialogContentProps>;
export default function DialogContent({
  ...dialogContentProps
}: DialogContentProps): ReactElement {
  const { emails, countItems, isFetchingPage, fetchNextPage } = useEmails();
  const [selectedEmail, setSelectedEmail] = useState<Email | undefined>(
    emails[0],
  );
  const [selectedEmailDetail, setSelectedEmailDetail] = useState<
    EmailDetail | LoadingContent | undefined
  >();

  useEffect(() => {
    if (selectedEmail) {
      setSelectedEmailDetail(new LoadingContent());
      selectedEmail
        .fullfil()
        .then((detail) => {
          setSelectedEmailDetail(detail);
        })
        .catch(() => {
          setSelectedEmailDetail(undefined);
        });
    }
  }, [selectedEmail]);

  if (emails.length === 0 && isFetchingPage) {
    return <Fragment />;
  }

  if (emails.length === 0) {
    return (
      <MuiDialogContent {...dialogContentProps}>
        <Alert severity="warning">There are no versions for this page.</Alert>
      </MuiDialogContent>
    );
  }

  return (
    <BaseDialogContent {...dialogContentProps}>
      {selectedEmail && (
        <DialogMainContent>
          {selectedEmailDetail instanceof LoadingContent && (
            <Spinner sx={{ width: "100%" }} />
          )}
          {selectedEmailDetail instanceof EmailDetail && (
            <EmailView email={selectedEmailDetail} />
          )}
        </DialogMainContent>
      )}
      <DialogTimelineWrapper>
        <DialogTimelineTitle>
          <Typography variant="body1" fontWeight={600}>
            Flow
          </Typography>
          <Typography variant="caption" paragraph>
            ({countItems === 1 ? "1 email" : `${countItems} emails`})
          </Typography>
        </DialogTimelineTitle>
        <Timeline
          emails={emails}
          totalItems={countItems}
          selected={selectedEmail}
          onSelected={setSelectedEmail}
          onScrollEnd={fetchNextPage}
        />
      </DialogTimelineWrapper>
    </BaseDialogContent>
  );
}
