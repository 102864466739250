import { Location, matchRoutes } from "react-router";
import { routes } from "routes/router";

export default class PageView extends Event {
  public static type = "page.view";

  constructor(public readonly location: Location) {
    super(PageView.type);
  }

  get matchedRoutes() {
    return matchRoutes(routes, this.location);
  }
}
