// ** Util Import
import { hexToRGBA } from "utils/hex-to-rgba";
import { Components, Theme, alpha } from "@mui/material";

const Pagination: Partial<Components<Theme>> = {
  MuiPaginationItem: {
    styleOverrides: {
      outlined: ({ theme }) => ({
        borderColor: alpha(theme.palette.customColors.main, 0.22),
      }),
      outlinedPrimary: ({ theme }) => ({
        "&.Mui-selected": {
          backgroundColor: hexToRGBA(theme.palette.primary.main, 0.12),
          "&:hover": {
            backgroundColor: `${hexToRGBA(
              theme.palette.primary.main,
              0.2,
            )} !important`,
          },
        },
      }),
      outlinedSecondary: ({ theme }) => ({
        "&.Mui-selected": {
          backgroundColor: hexToRGBA(theme.palette.secondary.main, 0.12),
          "&:hover": {
            backgroundColor: `${hexToRGBA(
              theme.palette.secondary.main,
              0.2,
            )} !important`,
          },
        },
      }),
      sizeSmall: ({ theme }) => ({
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
      }),
    },
  },
};

export default Pagination;
