import PromotionCode from "@unkover/unkover-api-sdk/dist/Model/Payment/PromotionCode/PromotionCode";
import HighlightedPromoList from "@unkover/unkover-api-sdk/dist/Model/Payment/PromotionCode/HighlightedPromoList";
import LoadingContent from "contexts/types/LoadingContent";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { createContext, useCallback, useEffect, useState } from "react";
import { Client, useSdk } from "sdk";
import useSubscriptions from "hooks/useSubscriptions";

export interface HighlightedPromoCodesContextState {
  promos: LoadingContent | PromotionCode[];
}

const HighlightedPromoCodesContext = createContext<
  HighlightedPromoCodesContextState | undefined
>(undefined);

export default HighlightedPromoCodesContext;

async function fetchPromos(client: Client) {
  const list: PromotionCode[] = [];

  const it = HighlightedPromoList.get(client);
  for await (const promo of it) {
    list.push(promo);
  }

  return list;
}

export function HighlightedPromoCodesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { currentSubscription } = useSubscriptions();
  const client = useSdk({ nullableWhenNotAuth: true });
  const throwAsyncError = useThrowAsyncError();
  const [state, setState] = useState<HighlightedPromoCodesContextState>({
    promos: new LoadingContent(),
  });

  const fetch = useCallback(async () => {
    if (null === client) {
      setState({
        promos: [],
      });
      return;
    }

    const promos = await fetchPromos(client);

    setState({
      promos,
    });
  }, [client]);

  useEffect(() => {
    fetch().catch(throwAsyncError);
  }, [fetch, currentSubscription]); // refresh on subscription change

  return (
    <HighlightedPromoCodesContext.Provider value={state}>
      {children}
    </HighlightedPromoCodesContext.Provider>
  );
}
