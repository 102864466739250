import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import { Email, EmailsList } from "@unkover/unkover-api-sdk/dist/Model/Email";
import EmailFlow from "@unkover/unkover-api-sdk/dist/Model/EmailFlow/EmailFlow";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { ReactElement, createContext, useEffect, useState } from "react";
import { useSdk } from "sdk";

interface EmailsContextState {
  emails: Email[];
  hasMore: boolean;
  countItems: number;
  isFetchingPage: boolean;
}

interface EmailsContextHandlers {
  fetchNextPage: () => Promise<void>;
  reset: () => void;
}

export const EmailsContext = createContext<
  (EmailsContextState & EmailsContextHandlers) | undefined
>(undefined);

interface EmailsProviderProps {
  children: React.ReactNode;
  emailFlow: Identifiable<EmailFlow>;
  perPage?: number;
}
export function EmailsProvider({
  children,
  emailFlow,
  perPage = 20,
}: EmailsProviderProps): ReactElement {
  const sdkClient = useSdk();
  const iterator = EmailsList.get(sdkClient);
  iterator.filterByEmailFlow([emailFlow]);

  const [items, setItems] = useState<Email[]>([]);
  const [isFetchingPage, setIsFetchingPage] = useState<boolean>(false);
  const [countItems, setCountItems] = useState<number>(iterator.length);
  const throwAsyncError = useThrowAsyncError();

  const pushFetchedItems = ({
    newItems,
    countItems,
  }: {
    newItems: Email[];
    countItems: number;
  }) => {
    setItems((items) => [...items, ...newItems]);
    setCountItems(countItems);
    setIsFetchingPage(false);
  };

  useEffect(() => {
    let isMounted = true;
    fetchItems()
      .then(({ items, countItems }) => {
        if (isMounted) pushFetchedItems({ newItems: items, countItems });
      })
      .catch((e) => {
        isMounted && throwAsyncError(e);
      })
      .finally(() => {
        isMounted && setIsFetchingPage(false);
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchItems = async (
    offset = 0,
  ): Promise<{
    items: Email[];
    countItems: number;
  }> => {
    const paginatedIterator = iterator
      .withMaxResults(perPage)
      .withFirstResult(offset);

    setIsFetchingPage(true);
    const emails: Email[] = [];
    for await (const iteratorPage of paginatedIterator) {
      emails.push(iteratorPage);
    }

    return { items: emails, countItems: paginatedIterator.length };
  };

  const hasMore = items.length < countItems;
  const fetchNextPage = async () => {
    if (isFetchingPage || !hasMore) return;

    const offset = items.length;

    return fetchItems(offset)
      .then(({ items, countItems }) => {
        pushFetchedItems({ newItems: items, countItems });
      })
      .catch(throwAsyncError)
      .finally(() => {
        setIsFetchingPage(false);
      });
  };

  return (
    <EmailsContext.Provider
      value={{
        emails: items,
        hasMore,
        countItems,
        isFetchingPage,
        fetchNextPage,
        reset: () => {
          setItems([]);
        },
      }}
    >
      {children}
    </EmailsContext.Provider>
  );
}
