import Spinner from "components/spinner/Spinner";
import LoadingContent from "contexts/types/LoadingContent";
import useAuth from "hooks/useAuth";
import { useUser } from "hooks/useUser";
import { ElementType, ReactElement, ReactNode } from "react";
import { Navigate, useLocation } from "react-router";
import { paths } from "routes/paths";

export type ProtectedRouteProps<P = Record<string, unknown>> = P &
  (
    | {
        children: ReactNode;
        page?: undefined;
      }
    | {
        children?: undefined;
        page: ElementType<P>;
      }
  );
export default function ProtectedRoute({
  children,
  page: PageContent,
  ...pageProps
}: ProtectedRouteProps): ReactElement {
  const auth = useAuth();
  const { user } = useUser();
  const location = useLocation();

  const redirectToQueryParams = new URLSearchParams({
    redirect: location.pathname + location.search + location.hash,
  }).toString();

  const loginUrl = `${paths.login.resolve()}?${redirectToQueryParams}`;
  // const otpVerifyUrl = `${paths.otpVerify.resolve()}?${redirectToQueryParams}`;

  if (user instanceof LoadingContent) {
    return <Spinner fullScreen />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={loginUrl} replace />;
  }

  // if (null !== user && !user.isVerified) {
  //   return <Navigate to={otpVerifyUrl} replace />;
  // }

  return undefined === PageContent ? (
    <>{children}</>
  ) : (
    <PageContent {...pageProps} />
  );
}
