import { debounce } from "@mui/material";
import PageView from "events/PageView";
import useDispatcher from "hooks/useDispatcher";
import { ReactElement, ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

interface TrackPageViewProps {
  children: ReactNode;
}
export default function TrackPageView({
  children,
}: TrackPageViewProps): ReactElement {
  const { dispatch } = useDispatcher();
  const location = useLocation();
  const trackPageView = useMemo(
    () =>
      debounce(() => {
        dispatch(new PageView(location));
      }, 100),
    [location, dispatch],
  );

  useEffect(() => {
    trackPageView();

    return () => {
      trackPageView.clear();
    };
  }, [trackPageView]);

  return <>{children}</>;
}
