import { Palette } from "@mui/material";

export const typography = (palette: Palette) => {
  return {
    fontFamily: [
      "Lexend",
      "Inter",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: 500,
      letterSpacing: "-1.5px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.16698,
    },
    h2: {
      fontWeight: 500,
      letterSpacing: "-0.5px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 500,
      letterSpacing: 0,
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.16667,
    },
    h4: {
      fontWeight: 500,
      letterSpacing: "0.25px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.23529,
    },
    h5: {
      fontWeight: 500,
      letterSpacing: 0,
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.33333,
    },
    h6: {
      letterSpacing: "0.15px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.6,
    },
    subtitle1: {
      letterSpacing: "0.15px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.75,
    },
    subtitle2: {
      letterSpacing: "0.1px",
      color: palette.text.secondary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.5,
    },
    body1: {
      letterSpacing: "0.15px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.5,
    },
    body2: {
      letterSpacing: "0.15px",
      color: palette.text.secondary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.5,
    },
    button: {
      letterSpacing: "0.3px",
      color: palette.text.primary,
      fontFeatureSettings: "'clig' off, 'liga' off",
    },
    caption: {
      letterSpacing: "0.4px",
      color: palette.text.secondary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.16667,
    },
    overline: {
      letterSpacing: "1px",
      color: palette.text.secondary,
      fontFeatureSettings: "'clig' off, 'liga' off",
      lineHeight: 1.16667,
    },
  };
};
