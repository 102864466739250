// ** Util Imports
import { Components, Theme } from "@mui/material";

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsSizeOverrides {
    xsmall: true;
    xxsmall: true;
  }
}

const SvgIcon: Partial<Components<Theme>> = {
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: "xsmall" },
        style: {
          fontSize: 16,
        },
      },
      {
        props: { fontSize: "xxsmall" },
        style: {
          fontSize: 12,
        },
      },
    ],
  },
};

export default SvgIcon;
