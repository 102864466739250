import HighlightedPromoCodesContext from "contexts/HighlightedPromoCodesContext";
import { useContext } from "react";

export default function useHighlightedPromos() {
  const context = useContext(HighlightedPromoCodesContext);

  if (context === undefined) {
    throw new Error(
      "useHighlightedPromos must be used within a HighlightedPromoCodesContext",
    );
  }

  return context;
}
