import { Analytics } from "@segment/analytics-next";
import posthog from "posthog-js";

const key = import.meta.env.REACT_APP_PUBLIC_POSTHOG_KEY;
const baseConfig = {
  api_host: import.meta.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

export const init = () => {
  posthog.init(key, baseConfig);
};

export const initWithSegment = (segment: Analytics) => {
  posthog.init(key, {
    ...baseConfig,
    capture_pageview: false,
    segment,
  });
};

export default posthog;
