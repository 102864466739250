import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [import.meta.env.REACT_APP_API_URL],
    }),
  ],

  beforeBreadcrumb(breadcrumb, hint) {
    if (
      undefined !== hint &&
      "xhr" in hint &&
      hint.xhr instanceof XMLHttpRequest &&
      breadcrumb.category === "xhr" &&
      hint.xhr.status > 400 &&
      hint.xhr.responseURL.includes(import.meta.env.REACT_APP_API_URL)
    ) {
      breadcrumb.data = {
        ...breadcrumb.data,
        response_body: hint.xhr.responseText,
      };
    }

    return breadcrumb;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    import.meta.env.REACT_APP_API_URL,
    import.meta.env.REACT_APP_CDN_URL,
  ],
});
