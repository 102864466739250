import Account from "@unkover/unkover-api-sdk/dist/Model/Account/Account";
import Company from "@unkover/unkover-api-sdk/dist/Model/Company/Company";
import OwnedCompany from "@unkover/unkover-api-sdk/dist/Model/Company/OwnedCompany";
import PendingCompany from "@unkover/unkover-api-sdk/dist/Model/Company/PendingCompany";
import LoadingContent from "contexts/types/LoadingContent";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { createContext, ReactElement, useEffect, useState } from "react";
import { useSdk } from "sdk";

export interface OwnedCompanyContextState {
  ownedCompany: OwnedCompany | LoadingContent | null;
}

export interface OwnedCompanyContextHandler {
  setOwnedCompany: (company: Company | PendingCompany) => Promise<OwnedCompany>;
}

export const OwnedCompanyContext = createContext<
  (OwnedCompanyContextState & OwnedCompanyContextHandler) | undefined
>(undefined);

type OwnedCompanyProviderProps = Readonly<{
  children: React.ReactNode;
}>;
export function OwnedCompanyProvider({
  children,
}: OwnedCompanyProviderProps): ReactElement {
  const client = useSdk({ nullableWhenNotAuth: true });
  const throwAsyncError = useThrowAsyncError();
  const [state, setState] = useState<OwnedCompanyContextState>({
    ownedCompany: new LoadingContent(),
  });

  useEffect(() => {
    let mounted = true;

    setState({ ownedCompany: new LoadingContent() });
    if (null !== client) {
      new OwnedCompany(client)
        .get()
        .then((ownedCompany) => {
          mounted && setState({ ownedCompany });
        })
        .catch(() => {
          mounted && setState({ ownedCompany: null });
        });
    } else {
      mounted && setState({ ownedCompany: null });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, throwAsyncError]);

  const setOwnedCompany: OwnedCompanyContextHandler["setOwnedCompany"] = async (
    company,
  ) => {
    if (null === client) {
      throw new Error("Cannot set owned company without authentication");
    }

    const account = new Account(client);

    if (company instanceof Company) {
      const ownedCompany = new OwnedCompany(client);
      ownedCompany.company = company;
      account.ownedCompany = ownedCompany;
    } else {
      account.ownedCompany = company;
    }

    const saved = await account.save();
    if (
      saved.ownedCompany instanceof PendingCompany ||
      saved.ownedCompany === null
    ) {
      throw new Error(
        "Unexpected pending company returned after account saving.",
      );
    }

    setState({ ownedCompany: saved.ownedCompany });

    return saved.ownedCompany;
  };

  return (
    <OwnedCompanyContext.Provider value={{ ...state, setOwnedCompany }}>
      {children}
    </OwnedCompanyContext.Provider>
  );
}
