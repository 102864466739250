import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import Impersonated from "events/user/Impersonated";
import useAuth from "hooks/useAuth";
import useDispatcher from "hooks/useDispatcher";
import { ReactElement, createContext, useEffect, useState } from "react";
import { AccessToken } from "services/auth";
import Impersonator from "services/auth/authenticator/Impersonator";

type ImpersonableUser = Identifiable<User> | string;
interface ImpersonateContextState {
  impersonatorToken: AccessToken | null;
  isImpersonating?: boolean;
}
interface ImpersonateContextHandlers {
  impersonate: (
    toImpersonate: ImpersonableUser,
    impersonator: User,
  ) => Promise<void>;
  stopImpersonating: () => void;
}

export const ImpersonateContext = createContext<
  (ImpersonateContextState & ImpersonateContextHandlers) | undefined
>(undefined);

interface ImpersonateProviderProps {
  children: ReactElement;
  impersonator: Impersonator;
}
export function ImpersonateProvider({
  children,
  impersonator,
}: ImpersonateProviderProps) {
  const { dispatch } = useDispatcher();
  const auth = useAuth();
  const [state, setState] = useState<ImpersonateContextState>({
    impersonatorToken: impersonator.getImpersonatorToken(),
    isImpersonating: impersonator.isImpersonating(),
  });

  const impersonate = async (
    toImpersonate: ImpersonableUser,
    impersonatorUser: User,
  ) => {
    const [impersonatedToken, impersonatorToken] =
      await impersonator.impersonate(toImpersonate, impersonatorUser);
    auth.loginWithToken(impersonatedToken);
    setState({
      impersonatorToken,
      isImpersonating: true,
    });

    dispatch(new Impersonated(toImpersonate, impersonatorUser));
  };

  const stopImpersonating = () => {
    if (null !== state.impersonatorToken) {
      auth.loginWithToken(state.impersonatorToken);
    }

    impersonator.stopImpersonating();
    setState({
      impersonatorToken: null,
      isImpersonating: false,
    });

    // force full reload to get the new user context without riskying to keep rendered older user data
    window.location.reload();
  };

  useEffect(() => {
    if (!auth.isAuthenticated && state.isImpersonating) {
      stopImpersonating();
      // auth.logout(); // force logout to avoid to stay in a weird state where I think to impersonating but I'm not
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, state.isImpersonating]);

  return (
    <ImpersonateContext.Provider
      value={{
        ...state,
        impersonate,
        stopImpersonating,
      }}
    >
      {children}
    </ImpersonateContext.Provider>
  );
}
