import { alpha, Components, switchClasses, Theme } from "@mui/material";

const Switch: Partial<Components<Theme>> = {
  MuiSwitch: {
    defaultProps: {
      focusVisibleClassName: ".Mui-focusVisible",
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        width: ownerState.size === "small" ? 34 : 40,
        height: ownerState.size === "small" ? 20 : 24,
        padding: 0,
        margin:
          ownerState.size === "small" ? theme.spacing(1) : theme.spacing(2),

        [`& .${switchClasses.switchBase}`]: {
          padding: 0,
          margin: 2,
          transitionDuration: "300ms",

          [`&.${switchClasses.checked}`]: {
            transform:
              ownerState.size === "small"
                ? "translateX(14px)"
                : "translateX(16px)",
            color: "#fff",
            [`& + .${switchClasses.track}`]: {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              border: 0,
            },
            [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
              opacity: 0.5,
            },
          },
          [`&.Mui-focusVisible .${switchClasses.thumb}`]: {
            color: theme.palette.primary.main,
            border: "6px solid #fff",
            boxShadow: "none",
          },
          [`&.${switchClasses.disabled} .${switchClasses.thumb}`]: {
            color: theme.palette.action.disabledBackground,
          },
        },
        [`& .${switchClasses.thumb}`]: {
          boxSizing: "border-box",
          width: ownerState.size === "small" ? 16 : 20,
          height: ownerState.size === "small" ? 16 : 20,
        },
        [`& .${switchClasses.track}`]: {
          borderRadius: ownerState.size === "small" ? 10 : 24,
          backgroundColor: alpha(theme.palette.common.black, 0.38),
          opacity: 1,
          transition: theme.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
      }),
    },
  },
};

export default Switch;
