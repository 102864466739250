import { EmailDetail } from "@unkover/unkover-api-sdk/dist/Model/Email";
import { ReactElement } from "react";
import unstyledBaseHtmlEmbed from "components/embed/HtmlEmbed";
import { styled } from "@mui/material";

const BaseHtmlEmbed = styled(unstyledBaseHtmlEmbed)(({ theme }) => ({
  background: "white",
  borderRadius: theme.shape.borderRadius * 2,
}));

type HtmlEmbedProps = Readonly<{ email: EmailDetail }>;
export default function HtmlEmbed({ email }: HtmlEmbedProps): ReactElement {
  return (
    <BaseHtmlEmbed
      html={email.source}
      title="Email View"
      style={{ background: "white" }}
    />
  );
}
