import { Components, Theme } from "@mui/material";

const link: Partial<Components<Theme>> = {
  MuiLink: {
    styleOverrides: {
      underlineHover: {
        textDecoration: "none",
      },
    },
  },
};

export default link;
