import { ReactElement, createContext, useEffect, useState } from "react";
import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import LoadingContent from "contexts/types/LoadingContent";
import useAuth from "hooks/useAuth";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { getOtpVerifier } from "services/auth";
import userProvider from "services/user";
import Fetched from "events/user/Fetched";
import useDispatcher from "hooks/useDispatcher";
import useImpersonate from "hooks/auth/useImpersonate";

export interface UserContextState {
  user: User | LoadingContent | null;
}

export interface UserContextHandlers {
  verifyEmailOtp: (otp: string) => Promise<void>;
  resendEmailOtp: () => Promise<void>;
}

export const UserContext = createContext<
  (UserContextState & UserContextHandlers) | undefined
>(undefined);

type UserProviderProps = Readonly<{
  fallback?: ReactElement;
  children: React.ReactNode;
}>;
export function UserProvider({
  children,
  fallback,
}: UserProviderProps): ReactElement {
  const [state, setState] = useState<UserContextState>({
    user: new LoadingContent(),
  });
  const auth = useAuth();
  const { isImpersonating } = useImpersonate();
  const throwAsyncError = useThrowAsyncError();
  const { dispatch } = useDispatcher();

  useEffect(() => {
    let mounted = true;

    userProvider
      .getUser()
      .then((user) => {
        mounted && setUser(user);
      })
      .catch((e) => {
        mounted && throwAsyncError(e);
      });

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, isImpersonating]);

  const setUser = (user: User | null) => {
    setState({ user });
    if (auth.isAuthenticated && user instanceof User) {
      dispatch(new Fetched(user));
    }
  };

  if (state.user instanceof LoadingContent) {
    return fallback ?? <div />;
  }

  const verifyEmailOtp = async (otp: string) => {
    if (auth.isAuthenticated) {
      const verifier = getOtpVerifier(auth.accessToken);
      await verifier.verifyEmailOpt(otp);
      userProvider.resetUser();
      userProvider.getUser().then(setUser).catch(throwAsyncError);

      return;
    }

    throw new Error("User not authenticated");
  };

  const resendEmailOtp = async () => {
    if (auth.isAuthenticated) {
      const verifier = getOtpVerifier(auth.accessToken);
      await verifier.resendEmailOpt();
      return;
    }

    throw new Error("User not authenticated");
  };

  return (
    <UserContext.Provider value={{ ...state, verifyEmailOtp, resendEmailOtp }}>
      {children}
    </UserContext.Provider>
  );
}
