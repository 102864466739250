import { IconButton, Stack, Typography } from "@mui/material";
import PageVersion from "@unkover/unkover-api-sdk/dist/Model/PageVersion/PageVersion";
import { ReactElement } from "react";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import {
  CurrentVersionImage,
  ImgComparisonSlider,
  PreviousVersionImage,
} from "components/pageDiff/slider";
import ResizableImageUrl from "services/media/ResizableImageUrl";
import FormattedDate from "components/date/FormattedDate";

type ComparatorSliderProps = Readonly<{
  newVersion: PageVersion;
  oldVersion?: PageVersion;
  canGoNext?: boolean;
  canGoPrevious?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
}>;
export default function ComparatorSlider({
  newVersion,
  oldVersion,
  canGoNext = true,
  canGoPrevious = true,
  onNext,
  onPrevious,
}: ComparatorSliderProps): ReactElement {
  const oldVersionImage = oldVersion
    ? new ResizableImageUrl(oldVersion.screenshotUrl)
    : undefined;
  const newVersionImage = new ResizableImageUrl(newVersion.screenshotUrl);

  return (
    <ImgComparisonSlider
      value={oldVersion ? 50 : 0}
      disabled={!oldVersion}
      previousLabel={
        <Stack direction="row-reverse" height="100%" alignItems="center">
          {oldVersion && (
            <>
              <IconButton
                disableRipple
                onClick={onPrevious}
                disabled={!canGoPrevious}
              >
                <ArrowBackIosOutlined fontSize="small" />
              </IconButton>
              <Typography variant="body2" fontWeight={600}>
                <FormattedDate date={oldVersion.createdAt} variant="short" />
              </Typography>
            </>
          )}
        </Stack>
      }
      currentLabel={
        <Stack
          direction="row"
          height="100%"
          alignItems="center"
          sx={{ pl: oldVersion ? 0 : 5 }}
        >
          {oldVersion && (
            <IconButton disableRipple onClick={onNext} disabled={!canGoNext}>
              <ArrowForwardIosOutlined fontSize="small" />
            </IconButton>
          )}
          <Typography variant="body2" fontWeight={600}>
            <FormattedDate date={newVersion.createdAt} variant="short" />
          </Typography>
        </Stack>
      }
    >
      {oldVersionImage && (
        <PreviousVersionImage
          key={String(oldVersionImage)}
          slot="first"
          src={oldVersionImage}
          width={1300}
        />
      )}
      <CurrentVersionImage
        key={String(newVersionImage)}
        slot="second"
        src={newVersionImage}
        width={1300}
      />
    </ImgComparisonSlider>
  );
}
