import PageVersion from "@unkover/unkover-api-sdk/dist/Model/PageVersion/PageVersion";
import { ReactElement, useRef } from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import {
  CodeOutlined,
  TextFieldsOutlined,
  TitleOutlined,
  WebOutlined,
} from "@mui/icons-material";
import { ceil } from "utils";
import BaseTimelineItem, {
  TimelineItemProps as BaseTimelineItemProps,
} from "components/dialog/timeline/TimelineItem";
import HumanDurationFromNow from "components/date/HumanDurationFromNow";
import TimelineItemImage from "components/dialog/timeline/TimelineItemImage";
import TimelineItemTitle from "components/dialog/timeline/TimelineItemTitle";
import TimelineItemDescription from "components/dialog/timeline/TimelineItemDescription";

function formatChangePercentage(percentage: number): string {
  let rountedPercentage = ceil(percentage);

  if (rountedPercentage < 10) {
    rountedPercentage = ceil(percentage, 1);
  }

  return `${rountedPercentage}%`;
}

type TimelineItemProps = Readonly<
  {
    version: PageVersion;
    highlighted?: boolean;
    firstVersion?: boolean;
  } & BaseTimelineItemProps
>;
export default function TimelineItem({
  version,
  highlighted = false,
  firstVersion = false,
  ...itemProps
}: TimelineItemProps): ReactElement {
  const elRef = useRef<HTMLDivElement>(null);

  if (highlighted) {
    const el = elRef.current;
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }

  return (
    <BaseTimelineItem ref={elRef} {...itemProps}>
      <TimelineSeparator>
        <TimelineDot>
          <WebOutlined fontSize="xsmall" />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction="column" spacing={2}>
          <TimelineItemTitle>
            <HumanDurationFromNow date={version.createdAt} />
          </TimelineItemTitle>
          <TimelineItemDescription>
            {!firstVersion && itemProps.selectedTop && (
              <>
                <Typography variant="caption" paragraph>
                  <TitleOutlined fontSize="xsmall" sx={{ mr: 1, mb: -1 }} />
                  {formatChangePercentage(
                    version.titlesDiff.changedPercentage,
                  )}{" "}
                  of titles changed
                </Typography>
                <Typography variant="caption" paragraph>
                  <TextFieldsOutlined
                    fontSize="xsmall"
                    sx={{ mr: 1, mb: -1 }}
                  />
                  {formatChangePercentage(
                    version.contentDiff.changedPercentage,
                  )}{" "}
                  of texts changed
                </Typography>
                <Typography variant="caption" paragraph>
                  <CodeOutlined fontSize="xsmall" sx={{ mr: 1, mb: -1 }} />
                  {formatChangePercentage(
                    version.sourceDiff.changedPercentage,
                  )}{" "}
                  of HTML changed
                </Typography>
              </>
            )}
            {firstVersion && (
              <Typography variant="caption" paragraph>
                First version available
              </Typography>
            )}
          </TimelineItemDescription>
          <TimelineItemImage
            src={version.screenshotUrl}
            width={200}
            height={132}
          />
        </Stack>
      </TimelineContent>
    </BaseTimelineItem>
  );
}
