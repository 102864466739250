import React, { Component, PropsWithChildren } from "react";
import UnauthorizedError from "exceptions/UnauthorizedError";
import AuthContext from "contexts/AuthContext";

interface UnathorizedErrorBoundaryState {
  hasError: boolean;
}

interface UnathorizedErrorBoundaryProps {
  onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

class UnathorizedErrorBoundary extends Component<
  PropsWithChildren<UnathorizedErrorBoundaryProps>,
  UnathorizedErrorBoundaryState
> {
  declare context: React.ContextType<typeof AuthContext>;
  static contextType = AuthContext;
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    if (!(error instanceof UnauthorizedError)) {
      throw error;
    }

    this.context?.logout("unauthorized");
    this.props.onError?.(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default UnathorizedErrorBoundary;
