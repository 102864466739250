import { ReactElement } from "react";
import BaseDialogTitle, {
  DialogTitleProps as BaseDialogTitleProps,
} from "components/dialog/timeline/DialogTitle";
import useEmails from "containers/emailFlows/hooks/useEmails";
import { CompanyAvatar } from "components/company/Avatar";
import { Typography } from "@mui/material";

type DialogTitleProps = Readonly<
  {
    onClose?: () => void;
  } & Omit<BaseDialogTitleProps, "loading">
>;
export default function DialogTitle({
  onClose,
  ...dialogTitleProps
}: DialogTitleProps): ReactElement {
  const { emails, isFetchingPage } = useEmails();

  const lastEmail = emails[0];
  const notFound = !isFetchingPage && !lastEmail;

  const loading = !lastEmail && isFetchingPage;

  return (
    <>
      {loading && <BaseDialogTitle loading avatar secondary />}
      {!loading && !notFound && lastEmail && (
        <BaseDialogTitle
          {...dialogTitleProps}
          avatar={
            <CompanyAvatar company={lastEmail.emailFlow.company} size={40} />
          }
          primary={
            <>
              {lastEmail.emailFlow.title}{" "}
              <Typography
                color="text.secondary"
                component="span"
                variant="h6"
                sx={{ pl: 1 }}
              >
                Email Flow
              </Typography>
            </>
          }
          onClose={onClose}
        />
      )}
    </>
  );
}
