import TrackerInterface, {
  PageParams,
} from "services/analytics/TrackerInterface";

export default class GATracker implements TrackerInterface {
  constructor(private readonly gtag: Gtag.Gtag) {}

  identify(): void {
    // implemented by Google Analytics library directly
  }

  track(event: string, properties?: Record<string, unknown>): void {
    this.gtag("event", event, properties);
  }

  page(params?: PageParams): void {
    if (
      undefined === params?.path ||
      params.path === window.location.pathname
    ) {
      // already tracked by Google Analytics library
      return;
    }

    const properties: Record<string, unknown> = {};

    if (params?.path) {
      properties.page_location = `${window.location.origin}${params.path}`;
    }

    if (params?.title) {
      properties.page_title = params.title;
    }

    this.gtag("event", "page_view", properties);
  }

  reset(): void {
    // not implemented
  }
}
