import { EmailsContext } from "containers/emailFlows/contexts/EmailsContext";
import { useContext } from "react";

export default function useEmails() {
  const context = useContext(EmailsContext);
  if (context === undefined) {
    throw new Error("useEmails must be used within a EmailsContext");
  }

  return context;
}
