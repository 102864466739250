import { timelineItemClasses as muiTimelineItemClasses } from "@mui/lab";

export const timelineItemClasses = {
  ...muiTimelineItemClasses,
  selectable: "TimelineItem-selectable",
  selected: "TimelineItem-selected",
  selectedTop: "TimelineItem-selectedTop",
  selectedBottom: "TimelineItem-selectedBottom",
  selectedMiddle: "TimelineItem-selectedMiddle",
  groupItem: "TimelineItem-groupItem",
  itemTitle: "TimelineItem-title",
  itemDescription: "TimelineItem-description",
  imageWrapper: "TimelineItem-imageWrapper",
};
