import { Box, BoxProps } from "@mui/material";
import { dialogClasses } from "components/dialog/timeline/dialogClasses";
import { ReactElement, ReactNode } from "react";
import clsx from "clsx";

type DialogMainContentProps = Readonly<
  {
    children?: ReactNode;
  } & BoxProps
>;
export default function DialogMainContent({
  children,
  ...boxProps
}: DialogMainContentProps): ReactElement {
  return (
    <Box
      {...boxProps}
      className={clsx(dialogClasses.dialogMainContent, boxProps.className)}
    >
      {children}
    </Box>
  );
}
