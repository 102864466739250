import { lazy, ReactElement, ReactNode } from "react";

const AccountConfiguredGuard = lazy(
  () => import("pages/preconditions/AccountConfiguredGuard"),
);

interface PreConditionsGuardProps {
  children: ReactNode;
}
export default function PreConditionsGuard({
  children,
}: PreConditionsGuardProps): ReactElement {
  return <AccountConfiguredGuard>{children}</AccountConfiguredGuard>;
}
