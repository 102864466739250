import { segmentTracker, gaTracker } from "services/analytics";
import { Listener } from "listeners/types";
import SuggestedAdded from "events/competitor/SuggestedAdded";

const trackSuggestedCompetitorsAdded: Listener<SuggestedAdded> = (event) => {
  const properties = {
    competitors: event.competitors
      .map((competitor) => competitor.company.domain)
      .join(","),
  };

  segmentTracker.track("Suggested Competitors Added", properties);
  gaTracker?.track("Demo_Competitors_Added", properties);
};

export default trackSuggestedCompetitorsAdded;
