import Created from "events/trackedPage/Created";
import { segmentTracker } from "services/analytics";
import { Listener } from "listeners/types";

const trackTrackedPageIgnored: Listener<Created> = (event) => {
  segmentTracker.track("Page Monitored Removed", {
    page_id: event.trackedPage.page.getIdentifier().id,
    page_url: event.trackedPage.page.url,
  });
};

export default trackTrackedPageIgnored;
