import ChannelAdded from "events/slack/ChannelAdded";
import { segmentTracker } from "services/analytics";
import { Listener } from "listeners/types";

const trackSlackChannelAdded: Listener<ChannelAdded> = (event) => {
  segmentTracker.track("Slack Channel Added", {
    channel_id: event.channel.id,
    channel_name: event.channel.getDisplayName(),
  });
};

export default trackSlackChannelAdded;
