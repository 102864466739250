import { ReactElement } from "react";
import {
  DialogContent as MuiDialogContent,
  Stack,
  DialogContentProps as MuiDialogContentProps,
} from "@mui/material";
import clsx from "clsx";
import { dialogClasses } from "components/dialog/timeline/dialogClasses";

export type DialogContentProps = Readonly<MuiDialogContentProps>;
export default function DialogContent({
  children,
  ...dialogContentProps
}: DialogContentProps): ReactElement {
  return (
    <MuiDialogContent
      {...dialogContentProps}
      className={clsx([
        dialogClasses.dialogContentWrapper,
        dialogContentProps.className,
      ])}
    >
      <Stack direction="row" width="100%" overflow="hidden">
        {children}
      </Stack>
    </MuiDialogContent>
  );
}
