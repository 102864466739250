import { segmentTracker } from "services/analytics";
import { Listener } from "listeners/types";
import Impersonated from "events/user/Impersonated";

const trackUserImpersonated: Listener<Impersonated> = (event) => {
  segmentTracker.track("Impersonated", {
    impersonator_id: event.impersonator.getIdentifier().id,
    impersonator_email: event.impersonator.email,
  });
};

export default trackUserImpersonated;
