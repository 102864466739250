import Fetched from "events/user/Fetched";
import { Listener } from "listeners/types";
import { setUser, setTag } from "@sentry/react";
import ImpersonatedUser from "@unkover/unkover-api-sdk/dist/Model/User/ImpersonatedUser";

const identifyUser: Listener<Fetched> = (event) => {
  setUser({
    id: String(event.user.getIdentifier().id),
    email: event.user.email,
    impersonatedBy:
      event.user instanceof ImpersonatedUser
        ? event.user.impersonator.email
        : undefined,
  });
  setTag("impersonated", `id:${event.user.getIdentifier().id}`);
};

export default identifyUser;
