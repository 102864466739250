import Subscription from "@unkover/unkover-api-sdk/dist/Model/Payment/Subscription";
import SubscriptionsList from "@unkover/unkover-api-sdk/dist/Model/Payment/SubscriptionsList";
import LoadingContent from "contexts/types/LoadingContent";
import PreconditionFailed from "exceptions/PreconditionFailed";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Client, useSdk } from "sdk";
import NotFoundException from "@solido/atlante-js/lib/Exception/NotFoundException";

export interface SubscriptionsContextState {
  currentSubscription: LoadingContent | Subscription | null;
}

const SubscriptionsContext = createContext<
  SubscriptionsContextState | undefined
>(undefined);

export default SubscriptionsContext;

async function fetchSubscriptions(client: Client) {
  try {
    const list: Subscription[] = [];

    const it = SubscriptionsList.get(client);
    for await (const subscription of it) {
      list.push(subscription);
    }

    return list;
  } catch (e) {
    if (e instanceof PreconditionFailed || e instanceof NotFoundException) {
      return [];
    }

    throw e;
  }
}

function sortByCreatedAtDesc(a: Subscription, b: Subscription) {
  return b.createdAt.getTime() - a.createdAt.getTime();
}

export function SubscriptionsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const throwAsyncError = useThrowAsyncError();
  const location = useLocation();
  const client = useSdk({ nullableWhenNotAuth: true });
  const [state, setState] = useState<SubscriptionsContextState>({
    currentSubscription: new LoadingContent(),
  });

  const fetch = useCallback(async () => {
    if (null === client) {
      setState({
        currentSubscription: null,
      });

      return;
    }

    const subscriptions = (await fetchSubscriptions(client)).sort(
      sortByCreatedAtDesc,
    );

    const lastSubscription = subscriptions[0] ?? null;
    const currentSubscription =
      !lastSubscription?.endsAt || lastSubscription.endsAt > new Date()
        ? lastSubscription
        : null;

    setState({
      currentSubscription,
    });
  }, [client]);

  useEffect(() => {
    if (state.currentSubscription instanceof Subscription) {
      return;
    }

    fetch().catch(throwAsyncError);
  }, [fetch, throwAsyncError, location, state.currentSubscription]);

  // reset sub after logout
  useEffect(() => {
    if (null === client && null !== state.currentSubscription) {
      setState({
        currentSubscription: null,
      });
    }
  }, [client, state.currentSubscription]);

  return (
    <SubscriptionsContext.Provider value={state}>
      {children}
    </SubscriptionsContext.Provider>
  );
}
