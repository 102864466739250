import Tracker from "services/analytics/Tracker";
import SegmentTracker from "./SegmentTracker";
import TrackerInterface from "services/analytics/TrackerInterface";
import segment from "lib/segment";
import { initWithSegment as initPostHogWithSegment } from "lib/posthog";
import GATracker from "services/analytics/GATracker";
import GTMTracker from "services/analytics/GTMTracker";

void segment.then(([segment, ctx]) => {
  initPostHogWithSegment(segment);

  return [segment, ctx];
});

export const segmentTracker = new SegmentTracker(segment);
export const gaTracker = window.gtag ? new GATracker(window.gtag) : undefined;
export const gtmTracker = new GTMTracker(window.dataLayer || []);

const trackers = [segmentTracker, gaTracker, gtmTracker];

export const tracker: TrackerInterface = new Tracker(
  ...(trackers.filter(Boolean) as TrackerInterface[]),
);
