import { ReactElement } from "react";
import TimelineItem, {
  TimelineItemProps,
} from "components/dialog/timeline/TimelineItem";
import { Typography } from "@mui/material";
import { timelineItemClasses } from "components/dialog/timeline/timelineItemClasses";
import clsx from "clsx";

type TimelineGroupItemProps = Readonly<TimelineItemProps>;
export default function TimelineGroupItem({
  children,
  ...timelineItemProps
}: TimelineGroupItemProps): ReactElement {
  return (
    <TimelineItem
      {...timelineItemProps}
      className={clsx(
        timelineItemClasses.groupItem,
        timelineItemProps.className,
      )}
    >
      <Typography variant="body2" fontWeight={600}>
        {children}
      </Typography>
    </TimelineItem>
  );
}
