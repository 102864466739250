import { PropsWithChildren, ReactElement } from "react";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import UnathorizedErrorBoundary from "components/error/UnauthorizedErrorBoundary";
import { useNavigate } from "react-router-dom";
import GeneralErrorAlert from "components/error/GeneralErrorAlert";

type RenderElementFunction = (
  resetError: () => void,
  error: unknown,
) => ReactElement;
export type ErrorBoundaryProps = PropsWithChildren<{
  fallback?: ReactElement | RenderElementFunction;
}>;

export default function ErrorBoundary({
  children,
  fallback,
}: ErrorBoundaryProps): ReactElement {
  const navigate = useNavigate();

  return (
    <SentryErrorBoundary
      fallback={
        fallback instanceof Function
          ? ({ resetError, error }) => fallback(resetError, error)
          : fallback ??
            (({ resetError }) => <GeneralErrorAlert resetError={resetError} />)
      }
    >
      <UnathorizedErrorBoundary
        onError={() => {
          // Refresh router tree to force redirect to login page
          navigate(0);
        }}
      >
        {children}
      </UnathorizedErrorBoundary>
    </SentryErrorBoundary>
  );
}
