import { ComponentProps, ReactElement } from "react";

type HtmlEmbedProps = Readonly<
  { html: string } & Omit<ComponentProps<"iframe">, "srcDoc">
>;
export default function HtmlEmbed({
  html,
  ...iframeProps
}: HtmlEmbedProps): ReactElement {
  return <iframe srcDoc={html} {...iframeProps} />;
}
