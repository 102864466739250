import { Components, Theme } from "@mui/material";

const Snackbar: Partial<Components<Theme>> = {
  MuiSnackbarContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.grey[900],
      }),
    },
  },
};

export default Snackbar;
