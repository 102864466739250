import PaymentMethodUpdateIntent from "@unkover/unkover-api-sdk/dist/Model/Payment/SessionIntent/PaymentMethodUpdateIntent";
import Subscription from "@unkover/unkover-api-sdk/dist/Model/Payment/Subscription";
import TopbarAlert from "components/alert/TopbarAlert";
import HumanDurationFromNow from "components/date/HumanDurationFromNow";
import FormattedMoney from "components/money/FormattedMoney";
import CustomerPortalButton from "components/subscription/CustomerPortalButton";
import { HighlightedPromoCodesProvider } from "contexts/HighlightedPromoCodesContext";
import LoadingContent from "contexts/types/LoadingContent";
import useHighlightedPromos from "hooks/useHighlightedPromos";
import useNow from "hooks/useNow";
import useSubscriptions from "hooks/useSubscriptions";
import { ReactElement } from "react";

function AlertComponent(): ReactElement {
  const now = useNow({ autoRefresh: true });
  const { promos } = useHighlightedPromos();
  const { currentSubscription } = useSubscriptions();
  const currentUrl = new URL(window.location.href);
  const portalIntent =
    currentSubscription instanceof Subscription
      ? new PaymentMethodUpdateIntent()
      : undefined;

  const currentPromo = promos instanceof LoadingContent ? undefined : promos[0];
  const expiresAt = currentPromo?.expiresAt;
  const expirationFromNow = (expiresAt?.getTime() ?? 0) - now.getTime();

  if (
    undefined === expiresAt ||
    expirationFromNow <= 0 ||
    undefined === currentPromo ||
    !(currentSubscription instanceof Subscription) ||
    currentSubscription.paymentMethod !== null
  ) {
    return <div />;
  }

  const formattedCouponAmount = currentPromo.coupon.percentOff ? (
    `${currentPromo.coupon.percentOff.toFixed(0)}%`
  ) : currentPromo.coupon.amountOff ? (
    <FormattedMoney money={currentPromo.coupon.amountOff} />
  ) : (
    ""
  );

  return (
    <TopbarAlert
      variant="promo"
      action={
        <CustomerPortalButton
          intent={portalIntent}
          returnUrl={currentUrl}
          variant="contained"
          size="small"
          color="secondary"
        >
          Get discount
        </CustomerPortalButton>
      }
    >
      <strong>{formattedCouponAmount} Discount:</strong> You have{" "}
      <strong>
        <HumanDurationFromNow
          autoRefresh
          withoutTooltip
          date={expiresAt}
          maxVisibleUnits={1}
          units={["days", "hours"]}
          listStyle="long"
        />
      </strong>{" "}
      of time to redeem this promo.
    </TopbarAlert>
  );
}

export default function HighlightedPromoAlertBar(): ReactElement {
  return (
    <HighlightedPromoCodesProvider>
      <AlertComponent />
    </HighlightedPromoCodesProvider>
  );
}
