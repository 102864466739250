import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import User from "@unkover/unkover-api-sdk/dist/Model/User/User";

export default class Impersonated extends Event {
  public static type = "user.impersonated";

  constructor(
    public readonly impersonated: Identifiable<User> | string,
    public readonly impersonator: User,
  ) {
    super(Impersonated.type);
  }
}
