import { createContext, ReactElement, useCallback } from "react";
import Dispatcher from "services/events/Dispatcher";

export interface EventListenersContext {
  dispatch(event: Event): void;
}

export const EventListenersContext = createContext<
  EventListenersContext | undefined
>(undefined);

interface EventListenersProviderProps {
  dispatcher: Dispatcher;
  children: React.ReactNode;
}
export function EventListenersProvider({
  dispatcher,
  children,
}: EventListenersProviderProps): ReactElement {
  const dispatch = useCallback(
    (event: Event) => {
      dispatcher.dispatch(event);
    },
    [dispatcher],
  );

  return (
    <EventListenersContext.Provider
      value={{
        dispatch,
      }}
    >
      {children}
    </EventListenersContext.Provider>
  );
}
