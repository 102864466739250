import UserCreated from "events/user/Created";
import { tracker } from "services/analytics";
import { Listener } from "listeners/types";
import { paths } from "routes/paths";

const trackRegisterSuccessAsPageView: Listener<UserCreated> = () => {
  tracker.page({
    path: paths.registerSuccess.resolve(),
  });
};

export default trackRegisterSuccessAsPageView;
