import { Components, Theme } from "@mui/material";

const Avatar: Partial<Components<Theme>> = {
  MuiAvatar: {
    styleOverrides: {
      colorDefault: ({ theme }) => ({
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey[200],
      }),
      rounded: {
        borderRadius: 5,
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      root: ({ theme }) => ({
        "&.pull-up": {
          "& .MuiAvatar-root": {
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              zIndex: 2,
              boxShadow: theme.shadows[3],
              transform: "translateY(-4px)",
            },
          },
        },
        justifyContent: "flex-end",
        ".MuiCard-root & .MuiAvatar-root": {
          borderColor: theme.palette.background.paper,
        },
      }),
    },
  },
};

export default Avatar;
