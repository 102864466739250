/* eslint-disable react-refresh/only-export-components */
import React from "react";
import { RouteObject } from "react-router-dom";
import { createBrowserRouter } from "./createBrowserRouter";

import PageDiffDialog from "pages/app/PageDiff/PageDiffDialog"; // Do not lazy load this component in order to avoid flickering when opening the dialog
import EmailFlowViewDialog from "pages/app/EmailFlow/ViewDialog"; // Do not lazy load this component in order to avoid flickering when opening the dialog
import { paths } from "routes/paths";
import { toRedirectRoute } from "routes/service/redirect";
import { redirects } from "routes/redirects";
import PublicRoute from "routes/components/PublicRoute";
import ProtectedRoute from "routes/components/ProtectedRoute";
import App from "App";
import PreConditionsGuard from "pages/PreConditionsGuard";

const AppRootLayout = React.lazy(() => import("pages/app/Layout"));
const AuthRootLayout = React.lazy(() => import("pages/auth/Layout"));
const Home = React.lazy(() => import("pages/app/Home"));
const Login = React.lazy(() => import("pages/auth/Login"));
const Logout = React.lazy(() => import("pages/auth/Logout"));
const Impersonate = React.lazy(() => import("pages/auth/Impersonate"));
const ForgotPassword = React.lazy(() => import("pages/auth/ForgotPassword"));
const CreateAccountStep = React.lazy(
  () => import("pages/auth/register/CreateAccountStep"),
);
const ConfigureAccount = React.lazy(
  () => import("pages/onboarding/ConfigureAccount"),
);
const PageNotFound = React.lazy(() => import("pages/404"));
const ErrorPage = React.lazy(() => import("pages/ErrorPage"));
const SlackConnect = React.lazy(() => import("pages/slack/SlackConnect"));
const ConnectError = React.lazy(() => import("pages/slack/ConnectError"));
const EmailOtpVerify = React.lazy(() => import("pages/auth/EmailOtpVerify"));

export const routes: RouteObject[] = [
  ...redirects.map(toRedirectRoute),
  {
    id: "root",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "auth",
        element: (
          <PublicRoute page={AuthRootLayout} redirectWhenAuthenticated />
        ),
        children: [
          {
            path: String(paths.login),
            element: <Login />,
          },
          {
            path: String(paths.forgotPassword),
            element: <ForgotPassword />,
          },
        ],
      },
      {
        id: "register",
        element: (
          <PublicRoute
            page={AuthRootLayout}
            withOffer
            redirectWhenAuthenticated
          />
        ),
        children: [
          {
            path: String(paths.register),
            element: <CreateAccountStep />,
          },
        ],
      },
      {
        id: "onboarding",
        element: (
          <PreConditionsGuard>
            <ProtectedRoute page={AuthRootLayout} />
          </PreConditionsGuard>
        ),
        children: [
          {
            path: String(paths.configureAccount),
            element: <ConfigureAccount />,
          },
        ],
      },
      {
        id: "app",
        element: (
          <PreConditionsGuard>
            <ProtectedRoute page={AppRootLayout} />
          </PreConditionsGuard>
        ),
        children: [
          {
            path: String(paths.home),
            element: <Home />,
            children: [
              {
                path: String(paths.pageDiff),
                element: <PageDiffDialog />,
              },
              {
                path: String(paths.emailFlowView),
                element: <EmailFlowViewDialog />,
              },
            ],
          },
        ],
      },
      {
        path: String(paths.otpVerify),
        element: <AuthRootLayout />,
        children: [
          {
            index: true,
            element: <EmailOtpVerify />,
          },
        ],
      },
      {
        path: String(paths.slackAuth),
        errorElement: <ConnectError />,
        element: <ProtectedRoute page={SlackConnect} />,
      },
      {
        path: String(paths.impersonate),
        element: <ProtectedRoute page={Impersonate} />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
      {
        path: String(paths.logout),
        element: <Logout />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
