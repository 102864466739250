import { Tooltip } from "@mui/material";
import FormattedDate from "components/date/FormattedDate";
import useNow from "hooks/useNow";
import {
  Duration,
  DurationLikeObject,
  DurationUnit,
  ToHumanDurationOptions,
} from "luxon";
import { ReactElement, useMemo } from "react";

const removeZeroUnits = (units: DurationLikeObject): DurationLikeObject =>
  Object.entries(units).reduce<DurationLikeObject>(
    (acc, [unit, value]) =>
      value === 0 ? acc : { ...acc, [unit]: value as number },
    {},
  );

interface HumanDurationFromNowProps extends ToHumanDurationOptions {
  date: Date;
  maxVisibleUnits?: number;
  withoutTooltip?: boolean;
  units?: DurationUnit[];
  autoRefresh?: boolean;
}
export default function HumanDurationFromNow({
  date,
  maxVisibleUnits = 1,
  withoutTooltip,
  units,
  autoRefresh,
  ...formatOps
}: HumanDurationFromNowProps): ReactElement {
  const now = useNow({ autoRefresh });
  const humaned = useMemo(() => {
    const dur = Duration.fromMillis(Math.abs(now.getTime() - date.getTime()));
    const rescaledDur = units
      ? dur.shiftTo(...units).normalize()
      : dur.rescale();

    const visibleUnits: DurationUnit[] = (
      Object.keys(removeZeroUnits(rescaledDur.toObject())) as DurationUnit[]
    ).slice(0, maxVisibleUnits);

    const visibleUnitsDuration = visibleUnits.reduce<DurationLikeObject>(
      (acc, unit) => ({
        ...acc,
        [unit]: Math.floor(rescaledDur.get(unit)),
      }),
      {},
    );

    // Increment 1 unit to latest visible unit to avoid showing 0 units
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const latestVisibleUnit = visibleUnits[visibleUnits.length - 1]!;
    const normalizedVisibleUnitsDuration =
      visibleUnits.length > 1
        ? visibleUnitsDuration
        : {
            ...visibleUnitsDuration,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [latestVisibleUnit]: visibleUnitsDuration[latestVisibleUnit]! + 1,
          };

    return Duration.fromObject(normalizedVisibleUnitsDuration).toHuman(
      formatOps,
    );
  }, [date, now, formatOps, maxVisibleUnits, units]);

  const content = (
    <span>
      {humaned}
      {now.getTime() > date.getTime() ? " ago" : ""}
    </span>
  );

  return withoutTooltip ? (
    content
  ) : (
    <Tooltip title={<FormattedDate date={date} />} arrow>
      {content}
    </Tooltip>
  );
}
