import { AllowNgrokRequest } from "sdk/decorators/AllowNgrokRequest";
import Client from "./client";
import { AccessToken } from "services/auth";

export const getClient = (authToken: AccessToken): Client => {
  const baseUrl = import.meta.env.REACT_APP_API_URL;

  const decorators = [];
  if (process.env.NODE_ENV === "development") {
    decorators.push(new AllowNgrokRequest());
  }

  return new Client(
    {
      authToken: String(authToken),
      baseUrl: baseUrl.endsWith("/") ? baseUrl : baseUrl + "/",
    },
    ...decorators,
  );
};
