/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CSSProperties,
  ComponentProps,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useRef,
} from "react";
import {
  HTMLImgComparisonSliderElement,
  ImgComparisonSlider as BaseImgComparisonSlider,
} from "@img-comparison-slider/react";
import { Box, GlobalStyles, IconButton, alpha, useTheme } from "@mui/material";
import { DragIndicatorOutlined } from "@mui/icons-material";
import {
  CurrentVersionLabel,
  PreviousVersionLabel,
  SliderMask,
  SliderHandler,
} from "components/pageDiff/slider";

type ImgComparisonSliderProps = Readonly<
  {
    disabled?: boolean;
    previousLabel?: ReactNode;
    currentLabel?: ReactNode;
    visibility?: CSSProperties["visibility"];
  } & ComponentProps<typeof BaseImgComparisonSlider>
>;
export default function ImgComparisonSlider({
  children,
  disabled = false,
  previousLabel,
  currentLabel,
  visibility,
  ...props
}: ImgComparisonSliderProps): ReactElement {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLImgComparisonSliderElement>(null);
  const sliderHandlerRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  // set exposure css variable to the "second" element in order to allow caption to position itself around the handler
  const syncExposureToSecondElement = (el: HTMLImgComparisonSliderElement) => {
    const exposure = el.value as string;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    el.shadowRoot
      ?.querySelector(".second")
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ?.style.setProperty("--exposure", `${exposure}%`);
    sliderWrapperRef.current?.style.setProperty("--exposure", `${exposure}%`);
  };

  const forwardEventToHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    // console.log(sliderRef.current, nativeEvent);
    sliderRef.current?.shadowRoot?.dispatchEvent(
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      new e.nativeEvent.constructor(e.nativeEvent.type, e.nativeEvent),
    );
  };

  return (
    <Box
      display="flex"
      position="relative"
      width="100%"
      ref={sliderWrapperRef}
      sx={{ "--exposure": `${props.value ?? 50}%` }}
    >
      <SliderMask visibility={visibility}>
        {previousLabel && (
          <PreviousVersionLabel>{previousLabel}</PreviousVersionLabel>
        )}
        {currentLabel && (
          <CurrentVersionLabel>{currentLabel}</CurrentVersionLabel>
        )}
        {!disabled && (
          <SliderHandler
            ref={sliderHandlerRef}
            onDragStart={forwardEventToHandler}
            onTouchStart={forwardEventToHandler}
            onTouchMove={forwardEventToHandler}
            onTouchEnd={forwardEventToHandler}
            onMouseDown={forwardEventToHandler}
          >
            <IconButton
              disableRipple
              sx={{
                backgroundColor: (theme) => theme.palette.customColors.bodyBg,
                color: (theme) => theme.palette.primary.main,
                cursor: "ew-resize",
              }}
            >
              <DragIndicatorOutlined />
            </IconButton>
          </SliderHandler>
        )}
      </SliderMask>
      <Box
        visibility={visibility}
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <GlobalStyles
          styles={{
            "img-comparison-slider": {
              "--divider-width": disabled ? 0 : "2px",
              "--divider-color": theme.palette.primary.main,
              "--divider-shadow": disabled
                ? "none"
                : `0 0 0 1px ${
                    theme.palette.customColors.bodyBg
                  }, 0 0 8px ${alpha(theme.palette.primary.main, 0.5)}`,
              "--handle-position-start": 0,
            },
          }}
        />
        <BaseImgComparisonSlider
          handle
          ref={sliderRef}
          onSlide={({ target }) => {
            syncExposureToSecondElement(target);
          }}
          onLoad={({ currentTarget }) => {
            syncExposureToSecondElement(currentTarget);
          }}
          style={{
            display: "block",
            width: "100%",
            minHeight: "100%",
          }}
          {...props}
        >
          {children}
          <Box
            slot="handle"
            height="10000vh"
            width={disabled ? 0 : sliderHandlerRef.current?.clientWidth ?? 40}
          >
            {/* This allow the custom handler above to forward the events because the slider library checks that the click event is inside this container */}
          </Box>
        </BaseImgComparisonSlider>
      </Box>
    </Box>
  );
}
