import StorageInterface from "services/auth/storage/StorageInterface";

export default class WebLocalStorage implements StorageInterface {
  constructor(private readonly localStorage: Storage) {}

  get(key: string, defaultValue?: string | undefined): string | null {
    return this.localStorage.getItem(key) ?? defaultValue ?? null;
  }

  set(key: string, value: string): void {
    this.localStorage.setItem(key, value);
  }

  delete(key: string): void {
    this.localStorage.removeItem(key);
  }

  clear(): void {
    this.localStorage.clear();
  }

  has(key: string): boolean {
    return this.localStorage.getItem(key) !== null;
  }
}
