import { Typography } from "@mui/material";
import { timelineItemClasses } from "components/dialog/timeline/timelineItemClasses";
import { ReactElement, ReactNode } from "react";

type TimelineItemTitleProps = Readonly<{ children: ReactNode }>;
export default function TimelineItemTitle({
  children,
}: TimelineItemTitleProps): ReactElement {
  return (
    <Typography variant="body1" className={timelineItemClasses.itemTitle}>
      {children}
    </Typography>
  );
}
