import UnprocessableContentResponse from "@unkover/unkover-api-sdk/dist/Requester/Response/UnprocessableContentResponse";
import { ApiErrorResponse } from "sdk/types/ApiErrorResponse";

export default class ValidationFailedError extends Error {
  constructor(
    public readonly errors: Record<string, string[]>,
    message?: string,
  ) {
    super(message);
    this.name = "ValidationFailedError";
  }

  static from(response: UnprocessableContentResponse): ValidationFailedError {
    const data = response.getData<ApiErrorResponse>();

    return new ValidationFailedError(data.errors ?? {}, data.message);
  }
}
