import CompetitorAdded from "events/competitor/Added";
import LoggedIn from "events/user/LoggedIn";
import LoggedOut from "events/user/LoggedOut";
import resetTracker from "listeners/tracking/resetTracker";
import trackCompetitorAdded from "listeners/tracking/trackCompetitorAdded";
import trackSlackConnected from "listeners/tracking/trackSlackConnected";
import trackUserLoggedIn from "listeners/tracking/trackUserLoggedIn";
import SlackConnected from "events/slack/Connected";
import type { Listeners } from "listeners/types";
import ChannelAdded from "events/slack/ChannelAdded";
import trackSlackChannelAdded from "listeners/tracking/trackSlackChannelAdded";
import NewUserEmailProvided from "events/user/NewUserEmailProvided";
import trackNewUserEmailProvided from "listeners/tracking/trackNewUserEmailProvided";
import OtpVerified from "events/user/OtpVerified";
import trackOtpVerified from "listeners/tracking/trackOtpVerified";
import TrackedPageCreated from "events/trackedPage/Created";
import TrackedPageIgnored from "events/trackedPage/Ignored";
import trackTrackedPageCreated from "listeners/tracking/trackTrackedPageCreated";
import trackTrackedPageIgnored from "listeners/tracking/trackTrackedPageIgnored";
import PageView from "events/PageView";
import trackPageView from "listeners/tracking/trackPageView";
import trackPageViewAsEvent from "listeners/tracking/trackPageDiffViewAsEvent";
import trackEmailFlowAsEvent from "listeners/tracking/trackEmailFlowViewAsEvent";
import UserFetched from "events/user/Fetched";
import identifySentryUser from "listeners/sentry/identifyUser";
import resetSentryUser from "listeners/sentry/resetUser";
import identifyTrackerUser from "listeners/tracking/identifyUser";
import UserCreated from "events/user/Created";
import trackUserCreated from "listeners/tracking/trackUserCreated";
import trackUserImpersonated from "listeners/tracking/trackUserImpersonated";
import UserImpersonated from "events/user/Impersonated";
import trackRegisterSuccessAsPageView from "listeners/tracking/trackRegisterSuccessAsPageDiffView";
import AccountConfigured from "events/account/Configured";
import trackAccountConfigured from "listeners/tracking/trackAccountConfigured";
import OwnedCompanyAdded from "events/ownedCompany/Added";
import trackOwnedAdded from "listeners/tracking/trackOwnedCompanyAdded";
import SuggestedCompetitorsAdded from "events/competitor/SuggestedAdded";
import trackSuggestedCompetitorsAdded from "listeners/tracking/trackSuggestedCompetitorsAdded";

// @TODO implement async listeners
export const listeners: Listeners = {
  [UserFetched.type]: [identifyTrackerUser, identifySentryUser],
  [UserImpersonated.type]: [trackUserImpersonated],
  [PageView.type]: [trackPageView, trackPageViewAsEvent, trackEmailFlowAsEvent],
  [UserCreated.type]: [trackUserCreated],
  [AccountConfigured.type]: [
    trackAccountConfigured,
    trackRegisterSuccessAsPageView,
  ],
  [LoggedIn.type]: [trackUserLoggedIn],
  [LoggedOut.type]: [resetTracker, resetSentryUser],
  [NewUserEmailProvided.type]: [trackNewUserEmailProvided],
  [OtpVerified.type]: [trackOtpVerified],
  [SlackConnected.type]: [trackSlackConnected],
  [ChannelAdded.type]: [trackSlackChannelAdded],
  [OwnedCompanyAdded.type]: [trackOwnedAdded],
  [CompetitorAdded.type]: [trackCompetitorAdded],
  [SuggestedCompetitorsAdded.type]: [trackSuggestedCompetitorsAdded],
  [TrackedPageCreated.type]: [trackTrackedPageCreated],
  [TrackedPageIgnored.type]: [trackTrackedPageIgnored],
};
