import { RouteObject } from "react-router";
import { redirect } from "react-router-dom";

export interface Redirect {
  from: string;
  to: string;
}
export const toRedirectRoute = ({ from, to }: Redirect): RouteObject => ({
  path: from,
  loader: ({ params }) => {
    const translatedTo = to.replace(
      /:(\w+)/g,
      (_, key) => params[String(key)] ?? "",
    );
    return redirect(translatedTo);
  },
});
