import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import AnonymousUser from "services/analytics/AnonymousUser";
import TrackerInterface, {
  PageParams,
} from "services/analytics/TrackerInterface";

export default class Tracker implements TrackerInterface {
  private trackers: TrackerInterface[];

  constructor(...trackers: TrackerInterface[]) {
    this.trackers = trackers;
  }

  identify(user: User | AnonymousUser): void {
    this.trackers.forEach((tracker) => {
      tracker.identify(user);
    });
  }

  track(event: string, properties?: Record<string, unknown> | undefined): void {
    this.trackers.forEach((tracker) => {
      tracker.track(event, properties);
    });
  }

  page(params?: PageParams): void {
    this.trackers.forEach((tracker) => {
      tracker.page(params);
    });
  }

  reset(): void {
    this.trackers.forEach((tracker) => {
      tracker.reset();
    });
  }
}
