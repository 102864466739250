import { Box, Typography } from "@mui/material";
import { TimelineItem as MuiTimelineItem } from "@mui/lab";
import PageVersion from "@unkover/unkover-api-sdk/dist/Model/PageVersion/PageVersion";
import { ReactElement, useState } from "react";
import Spinner from "components/spinner/Spinner";
import TimelineItems from "components/pageDiff/TimelineItems";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import BaseTimeline from "components/dialog/timeline/Timeline";

export type TimelineItemSelection = Readonly<{
  selectedVersion: PageVersion;
  compareAgainst?: PageVersion;
  highlighted?: boolean; // allow to identify if this selection must be highlighted (aka the view must be scrolled to this item, eg. after prev/next button click)
}>;

type TimelineProps = Readonly<{
  versions: PageVersion[];
  totalItems: number;
  selectedVersion?: TimelineItemSelection;
  onSelected: (selection: TimelineItemSelection) => void;
  onScrollEnd?: () => void | Promise<void>;
}>;
export default function Timeline({
  versions,
  selectedVersion,
  totalItems,
  onSelected,
  onScrollEnd,
}: TimelineProps): ReactElement {
  const throwAsyncError = useThrowAsyncError();
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
  const allVersionsAreLoaded = versions.length >= totalItems;

  const handleScrollEnd = async (): Promise<void> => {
    if (loadingScroll) return;

    setLoadingScroll(true);
    await onScrollEnd?.();
    setLoadingScroll(false);
  };

  return (
    <BaseTimeline
      onScroll={({ currentTarget }) => {
        const triggerOffset = 200;
        const scrollHeight =
          currentTarget.scrollHeight - currentTarget.offsetHeight; // currentTarget.scrollHeight includes the height of the scrollable area, including the padding
        const triggerThreshold = scrollHeight - triggerOffset;

        currentTarget.scrollTop > triggerThreshold &&
          handleScrollEnd().catch(throwAsyncError);
      }}
    >
      <TimelineItems
        versions={versions}
        selectedVersion={selectedVersion}
        hasMore={!allVersionsAreLoaded}
        onSelected={onSelected}
      />
      {allVersionsAreLoaded && (
        <MuiTimelineItem>
          <Typography
            variant="caption"
            paragraph
            textAlign="center"
            sx={{ mt: 5 }}
          >
            Yay! There are no other versions to load
          </Typography>
        </MuiTimelineItem>
      )}
      {loadingScroll && (
        <Box width="100%">
          <Spinner />
        </Box>
      )}
    </BaseTimeline>
  );
}
