import { Fragment, ReactElement, useState } from "react";
import {
  Alert,
  DialogContent as MuiDialogContent,
  Typography,
} from "@mui/material";
import usePageVersions from "containers/pageDiff/hooks/usePageVersions";
import ComparatorSlider from "components/pageDiff/ComparatorSlider";
import Timeline, { TimelineItemSelection } from "components/pageDiff/Timeline";
import BaseDialogContent, {
  DialogContentProps as BaseDialogContentProps,
} from "components/dialog/timeline/DialogContent";
import DialogMainContent from "components/dialog/timeline/DialogMainContent";
import DialogTimelineWrapper from "components/dialog/timeline/DialogTimelineWrapper";
import DialogTimelineTitle from "components/dialog/timeline/DialogTimelineTitle";

type DialogContentProps = Readonly<BaseDialogContentProps>;
export default function DialogContent({
  ...dialogContentProps
}: DialogContentProps): ReactElement {
  const { versions, countVersions, isFetchingPage, fetchNextPage } =
    usePageVersions();
  const [selectedVersion, setSelectedVersion] = useState<
    TimelineItemSelection | undefined
  >(
    versions[0]
      ? { selectedVersion: versions[0], compareAgainst: versions[1] }
      : undefined,
  );

  const handlePrevious = () => {
    const index = selectedVersion
      ? versions.indexOf(selectedVersion.selectedVersion)
      : 0;

    const newSelectedVersionIndex = Math.min(index + 1, countVersions - 2);
    const newCompareAgainstIndex = Math.min(index + 2, countVersions - 1);

    const newSelectedVersion = versions[newSelectedVersionIndex];
    const newCompareAgainst = versions[newCompareAgainstIndex];

    if (undefined === newSelectedVersion) {
      return;
    }

    setSelectedVersion({
      selectedVersion: newSelectedVersion,
      compareAgainst: newCompareAgainst,
      highlighted: true,
    });
  };

  const handleNext = () => {
    const index = selectedVersion
      ? versions.indexOf(selectedVersion.selectedVersion)
      : 0;

    const newSelectedVersionIndex = Math.max(index - 1, 0);
    const newCompareAgainstIndex = Math.max(index, 1);

    const newSelectedVersion = versions[newSelectedVersionIndex];
    const newCompareAgainst = versions[newCompareAgainstIndex];

    if (undefined === newSelectedVersion) {
      return;
    }

    setSelectedVersion({
      selectedVersion: newSelectedVersion,
      compareAgainst: newCompareAgainst,
      highlighted: true,
    });
  };

  if (versions.length === 0 && isFetchingPage) {
    return <Fragment />;
  }

  if (versions.length === 0) {
    return (
      <MuiDialogContent {...dialogContentProps}>
        <Alert severity="warning">There are no versions for this page.</Alert>
      </MuiDialogContent>
    );
  }

  return (
    <BaseDialogContent {...dialogContentProps}>
      {selectedVersion && (
        <DialogMainContent>
          <ComparatorSlider
            newVersion={selectedVersion.selectedVersion}
            oldVersion={selectedVersion.compareAgainst}
            onNext={handleNext}
            onPrevious={handlePrevious}
            canGoNext={versions.indexOf(selectedVersion.selectedVersion) > 0}
            canGoPrevious={
              versions.indexOf(selectedVersion.selectedVersion) <
              countVersions - 2
            }
          />
        </DialogMainContent>
      )}
      <DialogTimelineWrapper>
        <DialogTimelineTitle>
          <Typography variant="body1" fontWeight={600}>
            Timeline
          </Typography>
          <Typography variant="caption" paragraph>
            ({countVersions === 1 ? "1 version" : `${countVersions} versions`})
          </Typography>
        </DialogTimelineTitle>
        <Timeline
          versions={versions}
          totalItems={countVersions}
          selectedVersion={selectedVersion}
          onSelected={setSelectedVersion}
          onScrollEnd={fetchNextPage}
        />
      </DialogTimelineWrapper>
    </BaseDialogContent>
  );
}
