import { Box, Typography } from "@mui/material";
import { TimelineItem as MuiTimelineItem } from "@mui/lab";
import { ReactElement, useState } from "react";
import Spinner from "components/spinner/Spinner";
import TimelineItems from "components/emailFlows/timeline/TimelineItems";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import BaseTimeline from "components/dialog/timeline/Timeline";
import { Email } from "@unkover/unkover-api-sdk/dist/Model/Email";

export type TimelineItemSelection = Email;

type TimelineProps = Readonly<{
  emails: Email[];
  totalItems: number;
  selected?: TimelineItemSelection;
  onSelected: (selection: TimelineItemSelection) => void;
  onScrollEnd?: () => void | Promise<void>;
}>;
export default function Timeline({
  emails,
  selected,
  totalItems,
  onSelected,
  onScrollEnd,
}: TimelineProps): ReactElement {
  const throwAsyncError = useThrowAsyncError();
  const [loadingScroll, setLoadingScroll] = useState<boolean>(false);
  const allItemsAreLoaded = emails.length >= totalItems;

  const handleScrollEnd = async (): Promise<void> => {
    if (loadingScroll) return;

    setLoadingScroll(true);
    await onScrollEnd?.();
    setLoadingScroll(false);
  };

  return (
    <BaseTimeline
      onScroll={({ currentTarget }) => {
        const triggerOffset = 200;
        const scrollHeight =
          currentTarget.scrollHeight - currentTarget.offsetHeight; // currentTarget.scrollHeight includes the height of the scrollable area, including the padding
        const triggerThreshold = scrollHeight - triggerOffset;

        currentTarget.scrollTop > triggerThreshold &&
          handleScrollEnd().catch(throwAsyncError);
      }}
    >
      <TimelineItems
        emails={emails}
        selected={selected}
        onSelected={onSelected}
      />
      {allItemsAreLoaded && (
        <MuiTimelineItem>
          <Typography
            variant="caption"
            paragraph
            textAlign="center"
            sx={{ mt: 5 }}
          >
            Yay! There are no other emails to load
          </Typography>
        </MuiTimelineItem>
      )}
      {loadingScroll && (
        <Box width="100%">
          <Spinner />
        </Box>
      )}
    </BaseTimeline>
  );
}
