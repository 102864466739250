import { useEffect, useState } from "react";

interface UseNowParams {
  interval?: number;
  autoRefresh?: boolean;
}
const useNow = ({ interval = 1000, autoRefresh }: UseNowParams = {}): Date => {
  const [time, setTime] = useState(new Date());
  const updateTime = (): void => {
    setTime(new Date());
  };

  useEffect(() => {
    if (!autoRefresh) {
      return;
    }
    const _timer = setInterval(updateTime, interval);
    return (): void => {
      clearInterval(_timer);
    };
  }, [interval, autoRefresh]);

  return time;
};

export default useNow;
