import { ReactElement, createContext, useEffect, useState } from "react";
import LoadingContent from "contexts/types/LoadingContent";
import useAuth from "hooks/useAuth";
import { useSdk } from "sdk";
import LimitsResource from "@unkover/unkover-api-sdk/dist/Model/Limits/Limits";
import useThrowAsyncError from "hooks/useThrowAsyncError";

interface Limits {
  maxConfigurableCompetitors: number;
}

export type LimitsState = LoadingContent | Limits;

const initialState: LimitsState = new LoadingContent();
const defaultLimits: Limits = {
  maxConfigurableCompetitors: 5,
};

export const LimitsContext = createContext<LimitsState>(initialState);

type LimitsProviderProps = Readonly<{
  children: React.ReactNode;
}>;
export function LimitsProvider({
  children,
}: LimitsProviderProps): ReactElement {
  const throwAsyncError = useThrowAsyncError();
  const auth = useAuth();
  const client = useSdk({ nullableWhenNotAuth: true });
  const [state, setState] = useState<LimitsState>(
    !auth.isAuthenticated ? defaultLimits : initialState,
  );

  useEffect(() => {
    if (null !== client) {
      new LimitsResource(client)
        .get()
        .then((limits) => {
          setState({
            maxConfigurableCompetitors: limits.competitors ?? Infinity,
          });
        })
        .catch(throwAsyncError);
    }
  }, [throwAsyncError, client]);

  return (
    <LimitsContext.Provider value={state}>{children}</LimitsContext.Provider>
  );
}
