// ** Overrides Imports
import MuiCard from "./card";
import MuiChip from "./chip";
import MuiLink from "./link";
import MuiList from "./list";
import MuiMenu from "./menu";
import MuiTabs from "./tabs";
import MuiInput from "./input";
import MuiPaper from "./paper";
import MuiTable from "./table";
import MuiAlerts from "./alerts";
import MuiButton from "./button";
import MuiDialog from "./dialog";
import MuiRating from "./rating";
import MuiSelect from "./select";
import MuiAvatar from "./avatars";
import MuiPopover from "./popover";
import MuiTooltip from "./tooltip";
import MuiBackdrop from "./backdrop";
import MuiDataGrid from "./dataGrid";
import MuiSnackbar from "./snackbar";
import MuiSwitches from "./switches";
import MuiTimeline from "./timeline";
import MuiAccordion from "./accordion";
import MuiPagination from "./pagination";
import MuiTypography from "./typography";
import MuiToggleButton from "./toggleButton";
import MuiSvgIcon from "./svgIcon";
import MuiForm from "./form";
import { ThemeOptions } from "@mui/material";

const Overrides: ThemeOptions["components"] = Object.assign(
  MuiChip,
  MuiList,
  MuiMenu,
  MuiTabs,
  MuiCard,
  MuiInput,
  MuiAlerts,
  MuiButton,
  MuiDialog,
  MuiRating,
  MuiTable,
  MuiAvatar,
  MuiLink,
  MuiPopover,
  MuiTooltip,
  MuiBackdrop,
  MuiDataGrid,
  MuiPaper,
  MuiSnackbar,
  MuiSwitches,
  MuiTimeline,
  MuiAccordion,
  MuiSelect,
  MuiPagination,
  MuiTypography,
  MuiToggleButton,
  MuiSvgIcon,
  MuiForm,
) as ThemeOptions["components"];

export default Overrides;
