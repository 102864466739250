import SubscriptionsContext from "contexts/SubscriptionsContext";
import { useContext } from "react";

export default function useSubscription() {
  const context = useContext(SubscriptionsContext);

  if (context === undefined) {
    throw new Error(
      "useSubscription must be used within a SubscriptionsProvider",
    );
  }

  return context;
}
