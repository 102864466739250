import PageVersion from "@unkover/unkover-api-sdk/dist/Model/PageVersion/PageVersion";
import TimelineItem from "components/pageDiff/TimelineItem";
import { Fragment, ReactElement } from "react";
import { TimelineItemSelection } from "components/pageDiff/Timeline";
import TimelineGroupItem from "components/dialog/timeline/TimelineGroupItem";

type TimelineItemsProps = Readonly<{
  versions: PageVersion[];
  selectedVersion?: TimelineItemSelection;
  hasMore?: boolean;
  onSelected: (selection: TimelineItemSelection) => void;
}>;
export default function TimelineItems({
  versions,
  selectedVersion,
  hasMore = false,
  onSelected,
}: TimelineItemsProps): ReactElement {
  let previousYearForGrouping: number | undefined =
    versions[0]?.createdAt.getFullYear();

  const isSelected = (version: PageVersion): boolean =>
    selectedVersion?.selectedVersion.getIdentifier().id ===
    version.getIdentifier().id;

  const isSelectedAsComparing = (version: PageVersion): boolean =>
    selectedVersion?.compareAgainst?.getIdentifier().id ===
    version.getIdentifier().id;

  const isHighlighted = (version: PageVersion): boolean =>
    Boolean(
      selectedVersion?.highlighted &&
        selectedVersion.selectedVersion.getIdentifier().id ===
          version.getIdentifier().id,
    );

  const handleSelection = (selectedVersion: PageVersion): void => {
    const selectedIndex = Math.min(
      versions.indexOf(selectedVersion),
      versions.length - 2,
    );
    const compareAgainstIndex = Math.min(
      selectedIndex + 1,
      versions.length - 1,
    );

    const newSelectedVersion = versions[selectedIndex];
    const newCompareAgainst = versions[compareAgainstIndex];

    if (undefined === newSelectedVersion) {
      throw new Error("Selected version is undefined");
    }

    onSelected({
      selectedVersion: newSelectedVersion,
      compareAgainst: newCompareAgainst,
    });
  };

  return (
    <>
      {versions.map((version, index) => {
        const isYearDifferent =
          previousYearForGrouping !== version.createdAt.getFullYear();
        previousYearForGrouping = version.createdAt.getFullYear();

        return (
          <Fragment key={index}>
            {isYearDifferent && (
              <TimelineGroupItem
                selectedMiddle={isSelectedAsComparing(version)}
              >
                {version.createdAt.getFullYear()}
              </TimelineGroupItem>
            )}
            <TimelineItem
              selectable
              version={version}
              firstVersion={!hasMore && index === versions.length - 1}
              highlighted={isHighlighted(version)}
              selectedTop={isSelected(version)}
              selectedBottom={isSelectedAsComparing(version)}
              onClick={() => {
                handleSelection(version);
              }}
            />
          </Fragment>
        );
      })}
    </>
  );
}
