import { LaunchOutlined } from "@mui/icons-material";
import { Box, Link, LinkProps } from "@mui/material";
import { ReactElement } from "react";

export default function ExternalLink(props: LinkProps): ReactElement {
  return (
    <Link
      {...props}
      target="_blank"
      sx={{
        ...props.sx,
        maxWidth: "100%",
        display: "inline-flex",
        flexDirection: "row",
        overflow: "hidden" /* allow ellipsis inside */,
      }}
    >
      <Box
        component="span"
        sx={{
          display: "inline-block",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {props.children ?? props.href}
      </Box>
      <LaunchOutlined fontSize="xsmall" sx={{ ml: 1, height: "auto" }} />
    </Link>
  );
}
