import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { palette } from "theme/palette";
import { shadows } from "theme/shadows";
import { spacing } from "theme/spacing";
import { breakpoints } from "theme/breakpoints";
import { typography } from "theme/typography";
import components from "theme/overrides";

// A custom theme for this app
let theme = createTheme({
  palette: palette(),
  shadows,
  ...spacing,
  breakpoints: breakpoints(),
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  components,
  typography,
});

theme = responsiveFontSizes(theme);

export default theme;
