import { Components, Theme } from "@mui/material";

const Table: Partial<Components<Theme>> = {
  MuiTableContainer: {
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.shadows[0],
        borderTopColor: theme.palette.divider,
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        textTransform: "uppercase",
        "& .MuiTableCell-head": {
          fontSize: 12,
          fontWeight: 600,
          letterSpacing: "0.17px",
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiTableCell-body": {
          letterSpacing: "0.25px",
          color: theme.palette.text.secondary,
          "&:not(.MuiTableCell-sizeSmall):not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone)":
            {
              paddingTop: theme.spacing(3.5),
              paddingBottom: theme.spacing(3.5),
            },
        },
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiTableCell-head:not(.MuiTableCell-paddingCheckbox):first-of-type, & .MuiTableCell-root:not(.MuiTableCell-paddingCheckbox):first-of-type ":
          {
            paddingLeft: theme.spacing(5),
          },
        "& .MuiTableCell-head:last-child, & .MuiTableCell-root:last-child": {
          paddingRight: theme.spacing(5),
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
      }),
      paddingCheckbox: ({ theme }) => ({
        paddingLeft: theme.spacing(2),
      }),
      stickyHeader: ({ theme }) => ({
        backgroundColor: theme.palette.customColors.tableHeaderBg,
      }),
    },
  },
};

export default Table;
