// ** MUI Import
import { SxProps, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

type SpinnerProps = Readonly<{
  fullScreen?: boolean;
  sx?: SxProps<Theme>;
}>;
const Spinner = ({ fullScreen = false, sx }: SpinnerProps) => {
  return (
    <Box
      sx={{
        height: fullScreen ? "100vh" : undefined,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        py: 8,
        ...sx,
      }}
    >
      <CircularProgress disableShrink />
    </Box>
  );
};

export default Spinner;
