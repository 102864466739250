import { Components, Theme } from "@mui/material";

const toggleButton: Partial<Components<Theme>> = {
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
    },
  },
};

export default toggleButton;
