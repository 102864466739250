import LoggedIn from "events/user/LoggedIn";
import { tracker } from "services/analytics";
import { Listener } from "listeners/types";

const trackUserLoggedIn: Listener<LoggedIn> = (event) => {
  tracker.identify(event.user);

  // FROM BE
  // segmentTracker.track("Login", {
  //   id: event.user.getIdentifier().id,
  //   email: event.user.email,
  // });
};

export default trackUserLoggedIn;
