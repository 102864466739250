import { Box, styled, svgIconClasses, typographyClasses } from "@mui/material";

const LabelBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  width: "100%",
  top: 0,
  backgroundColor: theme.palette.primary.lightest,
  [`& .${typographyClasses.root}, & .${svgIconClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}));

export const PreviousVersionLabel = styled(LabelBox)(({ theme }) => ({
  right: "calc(100% - var(--exposure, 0))",
  height: theme.spacing(9),
  marginRight: 2, // compensate for the 2px handler divider
  [`& .${typographyClasses.root}, & .${svgIconClasses.root}`]: {
    opacity: 0.75,
  },
}));

export const CurrentVersionLabel = styled(LabelBox)(({ theme }) => ({
  left: "var(--exposure, 0)",
  height: theme.spacing(9),
  marginLeft: 2, // compensate for the 2px handler divider
}));
