import { ComponentProps, ReactElement, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import ResizableImageUrl from "services/media/ResizableImageUrl";
import { timelineItemClasses } from "components/dialog/timeline/timelineItemClasses";

type TimelineItemProps = Omit<ComponentProps<"img">, "src" | "srcSet"> & {
  src: string | URL;
  width: number;
  height: number;
};
export default function TimelineItemImage({
  src,
  width,
  height,
  ...imgProps
}: TimelineItemProps): ReactElement {
  const image = new ResizableImageUrl(src);
  const [loaded, setLoaded] = useState(false);

  return (
    <Box
      sx={{ width, height, position: "relative" }}
      className={timelineItemClasses.imageWrapper}
    >
      {!loaded && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={width}
          height={height}
          sx={{ position: "absolute", top: 0, left: 0 }}
        />
      )}
      <img
        srcSet={`${String(
          image.getImageUrl({ width: width * 2, cropHeight: height * 2 }),
        )} 2x`}
        src={String(image.getImageUrl({ width: width, cropHeight: height }))}
        loading="lazy"
        {...imgProps}
        onLoad={(e) => {
          setLoaded(true);
          imgProps.onLoad?.(e);
        }}
        style={{
          ...imgProps.style,
          visibility: !loaded ? "hidden" : undefined,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </Box>
  );
}
