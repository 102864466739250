import axios from "axios";
import { AccessToken } from "services/auth";

export const getClient = (token?: AccessToken) => {
  const apiUrl = import.meta.env.REACT_APP_API_URL;

  return axios.create({
    baseURL: `${apiUrl}/`,
    headers: {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${String(token)}` } : {}),
      ...(!import.meta.env.PROD ? { "ngrok-skip-browser-warning": "1" } : {}),
    },
  });
};
