// ** Util Imports
import { hexToRGBA } from "utils/hex-to-rgba";
import { Components, Theme, alpha } from "@mui/material";

const Chip: Partial<Components<Theme>> = {
  MuiChip: {
    styleOverrides: {
      root: {
        "&.MuiChip-rounded": {
          borderRadius: 4,
        },
      },
      outlined: ({ theme }) => ({
        "&.MuiChip-colorDefault": {
          borderColor: alpha(theme.palette.customColors.main, 0.22),
        },
      }),
      deleteIcon: {
        width: 18,
        height: 18,
      },
      avatar: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
      deletableColorPrimary: ({ theme }) => ({
        "&.MuiChip-light .MuiChip-deleteIcon": {
          color: hexToRGBA(theme.palette.primary.main, 0.7),
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
      }),
      deletableColorSecondary: ({ theme }) => ({
        "&.MuiChip-light .MuiChip-deleteIcon": {
          color: hexToRGBA(theme.palette.secondary.main, 0.7),
          "&:hover": {
            color: theme.palette.secondary.main,
          },
        },
      }),
      colorSuccess: ({ theme }) => ({
        backgroundColor: theme.palette.success.lightest,
        color: theme.palette.success.dark,
        "& .MuiChip-deleteIcon": {
          color: theme.palette.success.dark,
        },
      }),
    },
  },
};

export default Chip;
