import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import ImpersonatedUser from "@unkover/unkover-api-sdk/dist/Model/User/ImpersonatedUser";
import useImpersonate from "hooks/auth/useImpersonate";
import { useUser } from "hooks/useUser";
import { ReactElement } from "react";

export default function ImpersonatingWarningBar(): ReactElement {
  const { user } = useUser();
  const { stopImpersonating } = useImpersonate();

  if (!(user instanceof ImpersonatedUser)) {
    return <></>;
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: "error.light" }}>
      <Toolbar sx={{ justifyContent: "center" }}>
        <Typography variant="body2" color="error.contrastText">
          Careful, you're impersonating {user.email}.{" "}
        </Typography>
        <Button
          onClick={stopImpersonating}
          size="small"
          variant="outlined"
          sx={{ ml: 5 }}
          color="inherit"
        >
          Stop impersonating
        </Button>
      </Toolbar>
    </AppBar>
  );
}
