import { Alert } from "@mui/material";
import { ReactElement, ReactNode } from "react";

type GeneralErrorAlertProps = Readonly<{
  message?: ReactNode;
  resetError?: () => void;
}>;
export default function GeneralErrorAlert({
  message,
  resetError,
}: GeneralErrorAlertProps): ReactElement {
  return (
    <Alert severity="error">
      {message ?? "Ops, something went wrong."}
      {resetError && (
        <>
          {" "}
          Please,{" "}
          <a href="#_" onClick={resetError}>
            click here
          </a>{" "}
          to try again.
        </>
      )}
      {!resetError && <> Please, refresh the page to try again.</>}
    </Alert>
  );
}
