import { alpha } from "@mui/material";

export const palette = () => {
  // ** Vars
  const whiteColor = "#FFF";
  const darkColor = "#3a3541";
  const lightColor = "#e7e9ef";
  const mainColor = darkColor;

  const primaryGradient = "#C6A7FE";
  const defaultBgColor = "#F4F5FA";

  return {
    customColors: {
      dark: darkColor,
      main: mainColor,
      light: lightColor,
      primaryGradient: primaryGradient,
      bodyBg: "#F4F5FA",
      trackBg: "#F0F2F8",
      darkBg: "#28243D",
      lightBg: "#F4F5FA",
      tableHeaderBg: "#F9FAFC",
    },
    common: {
      black: "#000",
      white: whiteColor,
    },
    primary: {
      lightest: "#F0E8FE",
      lighter: "#B894FB",
      light: "#925AFB",
      main: "#8141F7",
      medium: "#6D43BC",
      dark: "#4D2F85",
      contrastText: whiteColor,
      outlinedSelectedBg: alpha("#8141f7", 0.2),
    },
    secondary: {
      light: "#5D5775",
      lighter: "#9E9DA3",
      lightest: "#EAEAEB",
      main: "#3D324D",
      medium: "#544E6A",
      dark: "#282133",
      darker: "#18141F",
      contrastText: whiteColor,
      outlinedOverBg: alpha("#382F47", 0.08),
    },
    error: {
      light: "#FF6166",
      main: "#FF4C51",
      dark: "#E04347",
      contrastText: whiteColor,
    },
    warning: {
      light: "#FFCA64",
      main: "#FFB400",
      medium: "#E09E00",
      dark: "#E09E00",
      contrastText: whiteColor,
    },
    info: {
      light: "#32BAFF",
      main: "#16B1FF",
      dark: "#139CE0",
      contrastText: whiteColor,
    },
    success: {
      lightest: "#EAF5EA",
      lighter: "#C9FFBD",
      light: "#6AD01F",
      main: "#56CA00",
      medium: "#4CB200",
      dark: "#378100",
      contrastText: whiteColor,
      outlinedBorder: alpha("#56CA00", 0.8),
      outlinedOverBg: alpha("#56CA00", 0.08),
      outlinedSelectedBg: alpha("#56CA00", 0.2),
    },
    rating: {
      lightest: "#FEF7E0",
      lighter: "#FDF2CE",
      light: "#FCE392",
      main: "#FFC300",
      medium: "#4CB200",
      dark: "#B28A06",
      contrastText: "#775C04",
      outlinedBorder: alpha("#D9A807", 0.8),
      outlinedOverBg: alpha("#F7BF08", 0.08),
      outlinedSelectedBg: alpha("#F7BF08", 0.2),
      get containedOverBg() {
        return this.light;
      },
    },
    get accent() {
      return {
        lightest: "#FCFAFF",
        lighter: this.primary?.lightest,
        light: this.primary?.lighter,
        main: this.primary?.light,
        medium: this.primary?.main,
        dark: this.primary?.medium,
        contrastText: this.primary?.contrastText,
        outlinedBorder: alpha("#925AFB", 0.8),
        outlinedOverBg: alpha("#925AFB", 0.08),
        outlinedSelectedBg: alpha("#925AFB", 0.2),
        get containedOverBg() {
          return this.medium;
        },
        get containedSelectedBg() {
          return this.dark;
        },
      };
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#F5F5F5",
      A200: "#EEEEEE",
      A400: "#BDBDBD",
      A700: "#616161",
    },
    text: {
      primary: alpha("#352C44", 0.87),
      secondary: alpha("#352C44", 0.55),
      disabled: alpha("#352C44", 0.3),
      inverted: "#EFECF3DE",
    },
    divider: alpha("#252651", 0.12),
    background: {
      paper: whiteColor,
      default: defaultBgColor,
    },
    action: {
      active: alpha(mainColor, 0.54),
      hover: alpha(mainColor, 0.04),
      selected: alpha(mainColor, 0.08),
      disabled: alpha("#382F47", 0.2),
      disabledBackground: alpha("#382F47", 0.12),
      focus: alpha(mainColor, 0.12),
    },
    surfaces: {
      body: "#F4F4FA",
      bodyDark: "#E7E7EF",
      extraBg: alpha("#382F47", 0.05),
      pageHeaderBg: "#F9F9FC",
      paperInverted: "#352C44",
      backdropOverlay: "#14151F",
    },
    get promo() {
      return {
        main: "#FFCC00",
        light: "#FCE792",
        lighter: "#FDF4CE",
        lightest: "#FEF8E0",
        medium: "#D9AF07",
        dark: "#B28A06",
        darker: "#443502",
        contrastText: "#775C04",
        outlinedBorder: alpha("#D9AF07", 0.8),
        outlinedOverBg: alpha("#F7C708", 0.08),
        outlinedSelectedBg: alpha("#F7C708", 0.2),
        get containedOverBg() {
          return this.light;
        },
        get containedSelectedBg() {
          return this.medium;
        },
      };
    },
    get tip() {
      return {
        main: "#FADE59",
        light: "#FCEA92",
        lighter: "#FDF5CE",
        lightest: "#FEF9E0",
        medium: "#D9B607",
        dark: "#B28706",
        darker: "#443302",
        contrastText: "#775A04",
        outlinedBorder: alpha("#D9B607", 0.8),
        outlinedOverBg: alpha("#F7CF08", 0.08),
        outlinedSelectedBg: alpha("#F7CF08", 0.2),
        get containedOverBg() {
          return this.light;
        },
        get containedSelectedBg() {
          return this.medium;
        },
      };
    },
    others: {
      divider: alpha("#252651", 0.12),
      fieldBg: "#FFFFFF",
      fieldBorder: alpha("#252651", 0.25),
    },
  };
};
