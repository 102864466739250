import { Box, styled } from "@mui/material";
import { ReactElement, ReactNode, createContext } from "react";
import toast, { Toaster } from "react-hot-toast";

type AlertId = string;
export class Alert {
  constructor(private readonly alertId: AlertId) {}

  dismiss() {
    toast.dismiss(this.alertId);
  }
}

interface AlertContextHandlers {
  alert: (message: ReactElement | string) => Alert;
  success: (message: ReactElement | string) => Alert;
  error: (message: ReactElement | string) => Alert;
  loading: (message: ReactElement | string) => Alert;
}

export const AlertContext = createContext<AlertContextHandlers | undefined>(
  undefined,
);

const ReactHotToast = styled(Box)(({ theme }) => {
  return {
    "& > div": {
      left: `${theme.spacing(6)} !important`,
      right: `${theme.spacing(6)} !important`,
      bottom: `${theme.spacing(6)} !important`,
      zIndex: `${theme.zIndex.drawer - 1} !important`,
    },
    "& .react-hot-toast": {
      zIndex: "-1 !important",
      fontWeight: 400,
      fontSize: "1rem",
      borderRadius: "5px",
      letterSpacing: "0.14px",
      color: theme.palette.text.primary,
      background: theme.palette.background.paper,
      boxShadow:
        theme.palette.mode === "light"
          ? "0px 4px 10px -4px rgba(58, 53, 65, 0.6)"
          : "0px 8px 16px -4px rgba(19, 17, 32, 0.65)",
      "&>:first-of-type:not([role])>:first-of-type": {
        width: 14,
        height: 14,
      },
    },
  };
});

export function AlertProvider({ children }: { children: ReactNode }) {
  const makeAlertFrom = (alertId: AlertId) => new Alert(alertId);

  const alert = (message: ReactElement | string) => {
    return makeAlertFrom(toast(message));
  };

  const success = (message: ReactElement | string) => {
    return makeAlertFrom(toast.success(message));
  };

  const error = (message: ReactElement | string) => {
    return makeAlertFrom(toast.error(message));
  };

  const loading = (message: ReactElement | string) => {
    return makeAlertFrom(toast.loading(message));
  };

  return (
    <AlertContext.Provider value={{ alert, success, error, loading }}>
      <ReactHotToast>
        <Toaster
          position="top-right"
          toastOptions={{ className: "react-hot-toast" }}
        />
      </ReactHotToast>
      {children}
    </AlertContext.Provider>
  );
}
