import NewUserEmailProvided from "events/user/NewUserEmailProvided";
import { tracker, segmentTracker } from "services/analytics";
import AnonymousUser from "services/analytics/AnonymousUser";
import { Listener } from "listeners/types";

const trackNewUserEmailProvided: Listener<NewUserEmailProvided> = (event) => {
  const user = new AnonymousUser(event.email);

  tracker.identify(user);
  segmentTracker.track("New Account Step 1", {
    email: user.email,
  });
};

export default trackNewUserEmailProvided;
