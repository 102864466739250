import { getClient } from "services/api/client";
import {
  AccessToken,
  Authenticator,
  OtpVerifier,
  WebLocalStorage,
} from "services/auth";
import Impersonator from "services/auth/authenticator/Impersonator";
import ImpersonateRequestStorage from "services/auth/authenticator/storage/ImpersonateRequestStorage";
import ImpersonatorStorage from "services/auth/authenticator/storage/ImpersonatorStorage";
import CookieStorage from "services/auth/storage/CookieStorage";

export function getAuthenticator() {
  return new Authenticator(
    getClient(),
    new CookieStorage(),
    localStorage ? new WebLocalStorage(localStorage) : undefined,
  );
}

export function getImpersonator(authenticator: Authenticator) {
  return new Impersonator(
    authenticator,
    new ImpersonatorStorage(new CookieStorage()),
    new ImpersonateRequestStorage(new CookieStorage()),
  );
}

export function getOtpVerifier(accessToken: AccessToken) {
  return new OtpVerifier(getClient(accessToken));
}
