import { Stack } from "@mui/material";
import { dialogClasses } from "components/dialog/timeline/dialogClasses";
import { ReactElement, ReactNode } from "react";
import clsx from "clsx";

type DialogTimelineTitleProps = Readonly<{ children: ReactNode }>;
export default function DialogTimelineTitle({
  children,
}: DialogTimelineTitleProps): ReactElement {
  return (
    <Stack
      spacing={3}
      sx={{ p: 5, whiteSpace: "nowrap" }}
      direction="row"
      alignItems="baseline"
      className={clsx(dialogClasses.dialogTimelineTitle)}
    >
      {children}
    </Stack>
  );
}
