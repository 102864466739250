import { Skeleton, skeletonClasses, styled } from "@mui/material";
import { ComponentProps, ReactElement, useState } from "react";
import ResizableImageUrl from "services/media/ResizableImageUrl";

const Figure = styled("figure")(({ theme }) => ({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  margin: 0,
  padding: theme.spacing(9, 5, 5).toString(),
  "& img": {
    width: "100%",
  },
  [`& .${skeletonClasses.root}`]: {
    height: "100vh",
    backgroundColor: theme.palette.background.default,
  },
}));

type ImageProps = Readonly<
  { src: string | URL; width: number; height?: number } & ComponentProps<
    typeof Figure
  >
>;

export function Image({
  src,
  width,
  height,
  ...imageProps
}: ImageProps): ReactElement {
  const [imageLoaded, setImageLoaded] = useState(false);
  const finalSrc = String(
    src instanceof ResizableImageUrl
      ? src.getImageUrl({ width, cropHeight: height })
      : src,
  );

  return (
    <Figure {...imageProps}>
      {!imageLoaded && (
        <Skeleton component="div" variant="rectangular" animation="wave" />
      )}
      <img
        src={finalSrc}
        width={width}
        onLoad={() => {
          setImageLoaded(true);
        }}
        style={{ visibility: !imageLoaded ? "hidden" : undefined }}
      />
    </Figure>
  );
}

export const PreviousVersionImage = styled(Image)(() => ({
  // backgroundColor: theme.palette.primary.dark,
}));

export const CurrentVersionImage = styled(Image)(() => ({
  // backgroundColor: theme.palette.primary.main,
}));
