import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import Page from "@unkover/unkover-api-sdk/dist/Model/Page/Page";
import PageVersion from "@unkover/unkover-api-sdk/dist/Model/PageVersion/PageVersion";
import PageVersionsList from "@unkover/unkover-api-sdk/dist/Model/PageVersion/PageVersionsList";
import useThrowAsyncError from "hooks/useThrowAsyncError";
import { ReactElement, createContext, useEffect, useState } from "react";
import { useSdk } from "sdk";

interface PageVersionsContextState {
  versions: PageVersion[];
  hasMore: boolean;
  countVersions: number;
  isFetchingPage: boolean;
}

interface PageVersionsContextHandlers {
  fetchNextPage: () => Promise<void>;
  reset: () => void;
}

export const PageVersionsContext = createContext<
  (PageVersionsContextState & PageVersionsContextHandlers) | undefined
>(undefined);

interface PageVersionsProviderProps {
  children: React.ReactNode;
  page: Identifiable<Page>;
  perPage?: number;
}
export function PageVersionsProvider({
  children,
  page,
  perPage = 20,
}: PageVersionsProviderProps): ReactElement {
  const sdkClient = useSdk();
  const versionsIterator = PageVersionsList.get(sdkClient);
  versionsIterator.filterByPage([page]);

  const [versions, setVersions] = useState<PageVersion[]>([]);
  const [isFetchingPage, setIsFetchingPage] = useState<boolean>(false);
  const [countVersions, setCountVersions] = useState<number>(
    versionsIterator.length,
  );
  const throwAsyncError = useThrowAsyncError();

  const pushFetchedVersions = ({
    newVersions,
    countVersions,
  }: {
    newVersions: PageVersion[];
    countVersions: number;
  }) => {
    setVersions((versions) => [...versions, ...newVersions]);
    setCountVersions(countVersions);
    setIsFetchingPage(false);
  };

  useEffect(() => {
    let isMounted = true;
    fetchItems()
      .then(({ versions, countVersions }) => {
        if (isMounted)
          pushFetchedVersions({ newVersions: versions, countVersions });
      })
      .catch((e) => {
        isMounted && throwAsyncError(e);
      })
      .finally(() => {
        isMounted && setIsFetchingPage(false);
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchItems = async (
    offset = 0,
  ): Promise<{
    versions: PageVersion[];
    countVersions: number;
  }> => {
    const paginatedIterator = versionsIterator
      .withMaxResults(perPage)
      .withFirstResult(offset);

    setIsFetchingPage(true);
    const versions: PageVersion[] = [];
    for await (const pageVersion of paginatedIterator) {
      versions.push(pageVersion);
    }

    return { versions, countVersions: paginatedIterator.length };
  };

  const hasMore = versions.length < countVersions;
  const fetchNextPage = async () => {
    if (isFetchingPage || !hasMore) return;

    const offset = versions.length;

    return fetchItems(offset)
      .then(({ versions, countVersions }) => {
        pushFetchedVersions({ newVersions: versions, countVersions });
      })
      .catch(throwAsyncError)
      .finally(() => {
        setIsFetchingPage(false);
      });
  };

  return (
    <PageVersionsContext.Provider
      value={{
        versions,
        hasMore,
        countVersions,
        isFetchingPage,
        fetchNextPage,
        reset: () => {
          setVersions([]);
        },
      }}
    >
      {children}
    </PageVersionsContext.Provider>
  );
}
