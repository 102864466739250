import { segmentTracker } from "services/analytics";
import { Listener } from "listeners/types";
import Added from "events/ownedCompany/Added";

const trackOwnedAdded: Listener<Added> = (event) => {
  // FROM BE
  // segmentTracker.track("Owned Company Added", {
  //   company_id: String(event.ownedCompany.getIdentifier().id),
  //   company_name: event.ownedCompany.company.name,
  //   company_domain: event.ownedCompany.company.domain,
  // });
};

export default trackOwnedAdded;
