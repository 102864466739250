import { PageVersionsContext } from "containers/pageDiff/contexts/PageVersionsContext";
import { useContext } from "react";

export default function usePageVersions() {
  const context = useContext(PageVersionsContext);
  if (context === undefined) {
    throw new Error(
      "usePageVersions must be used within a PageVersionsProvider",
    );
  }

  return context;
}
