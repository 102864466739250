interface ResizeOptions {
  width?: number;
  height?: number;
  cropWidth?: number;
  cropHeight?: number;
}

export default class ResizableImageUrl extends URL {
  public getImageUrl(opts?: ResizeOptions): URL {
    if (undefined === opts) {
      return this;
    }

    const resizedUrl = new URL(this);

    if (undefined !== opts.width) {
      resizedUrl.searchParams.set("w", String(opts.width));
    }

    if (undefined !== opts.height) {
      resizedUrl.searchParams.set("h", String(opts.height));
    }

    if (undefined !== opts.cropWidth) {
      resizedUrl.searchParams.set("cw", String(opts.cropWidth));
    }

    if (undefined !== opts.cropHeight) {
      resizedUrl.searchParams.set("ch", String(opts.cropHeight));
    }

    return resizedUrl;
  }
}
