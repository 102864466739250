import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import { AxiosError, AxiosInstance } from "axios";
import { getClient } from "sdk";
import { AccessToken } from "services/auth";
import BadCredentials from "services/auth/exceptions/BadCredentials";
import StorageInterface from "services/auth/storage/StorageInterface";

export default class Authenticator {
  private KEY = "accessToken";

  constructor(
    protected readonly _client: AxiosInstance,
    protected readonly _storage: StorageInterface,
    protected readonly _deprecatedStorate?: StorageInterface,
  ) {}

  public async authenticate(
    email: string,
    password: string,
  ): Promise<[AccessToken, User]> {
    try {
      const response = await this._client.post<unknown>("/login", {
        email,
        password,
      });

      if (
        typeof response.data !== "object" ||
        response.data === null ||
        !("token" in response.data) ||
        typeof response.data.token !== "string" ||
        !("user" in response.data) ||
        typeof response.data.user !== "object" ||
        response.data.user === null ||
        !("user" in response.data.user)
      ) {
        throw new Error("Malformed response");
      }

      const accessToken = new AccessToken(response.data.token);

      return [
        this.authenticateWithToken(accessToken),
        new User(getClient(accessToken))._fromObject(response.data.user.user),
      ];
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        throw new BadCredentials(error);
      }

      throw error;
    }
  }

  public authenticateWithToken(token: AccessToken): AccessToken {
    this._storage.set(this.KEY, String(token));

    return token;
  }

  public logout(): void {
    this._storage.delete(this.KEY);
  }

  public getAccessToken(): AccessToken | null {
    this._migrateDeprecatedStorage();

    const token = this._storage.get(this.KEY);

    if (token === null) {
      return null;
    }

    return new AccessToken(token);
  }

  public isAuthenticated(): boolean {
    return this.getAccessToken() !== null;
  }

  private _migrateDeprecatedStorage(): void {
    if (this._deprecatedStorate === undefined) {
      return;
    }

    if (this._deprecatedStorate.has(this.KEY)) {
      this.authenticateWithToken(
        new AccessToken(this._deprecatedStorate.get(this.KEY) ?? ""),
      );

      this._deprecatedStorate.delete(this.KEY);
    }
  }
}
