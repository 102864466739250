import { ReactElement, forwardRef } from "react";
import {
  TimelineItem as MuiTimelineItem,
  TimelineItemProps as MuiTimelineItemProps,
} from "@mui/lab";
import clsx from "clsx";
import { timelineItemClasses } from "components/dialog/timeline/timelineItemClasses";

interface Selectable {
  selectable?: boolean;
  selected?: boolean;
  selectedTop?: boolean;
  selectedBottom?: boolean;
  selectedMiddle?: boolean;
}

export type TimelineItemProps = Readonly<Selectable & MuiTimelineItemProps>;
export default forwardRef(function TimelineItem(
  {
    selectable = false,
    selected = false,
    selectedTop = false,
    selectedBottom = false,
    selectedMiddle = false,
    children,
    ...timelineItemProps
  }: TimelineItemProps,
  ref,
): ReactElement {
  return (
    <MuiTimelineItem
      ref={ref}
      {...timelineItemProps}
      className={clsx(
        {
          [timelineItemClasses.selectable]: selectable,
          [timelineItemClasses.selected]:
            selected || selectedTop || selectedBottom || selectedMiddle,
          [timelineItemClasses.selectedTop]: selectedTop,
          [timelineItemClasses.selectedBottom]: selectedBottom,
          [timelineItemClasses.selectedMiddle]: selectedMiddle,
        },
        timelineItemProps.className,
      )}
    >
      {children}
    </MuiTimelineItem>
  );
});
