import Identifiable from "@unkover/unkover-api-sdk/dist/Identifiable";
import User from "@unkover/unkover-api-sdk/dist/Model/User/User";
import ResourceIdentifier from "@unkover/unkover-api-sdk/dist/ResourceIdentifier";
import { StorageInterface } from "services/auth/storage";

type ImpersonableUser = Identifiable<User> | string;
export default class ImpersonateRequestStorage {
  private KEY = "impersonatorRequest";

  constructor(
    protected readonly _storage: StorageInterface,
    protected readonly _deprecatedStorage?: StorageInterface,
  ) {}

  public get(): ImpersonableUser | null {
    this._migrateDeprecatedStorage();

    const request = this._storage.get(this.KEY);
    if (request === null) {
      return null;
    }

    return this._deserialize(request);
  }

  public store(request: ImpersonableUser): ImpersonableUser {
    this._storage.set(this.KEY, this._serialize(request));

    return request;
  }

  public clear = () => {
    this._storage.delete(this.KEY);
  };

  private _serialize = (request: ImpersonableUser): string => {
    if (typeof request === "string") {
      return JSON.stringify({ email: request });
    }

    return JSON.stringify({ id: request.getIdentifier().id as unknown });
  };

  private _deserialize = (request: string): ImpersonableUser => {
    const data = JSON.parse(request) as { email?: string; id?: string };

    if (data.email) {
      return data.email;
    }

    return new ResourceIdentifier<User>(data.id);
  };

  private _migrateDeprecatedStorage(): void {
    if (this._deprecatedStorage === undefined) {
      return;
    }

    if (this._deprecatedStorage.has(this.KEY)) {
      this._storage.set(this.KEY, this._deprecatedStorage.get(this.KEY) ?? "");
      this._deprecatedStorage.delete(this.KEY);
    }
  }
}
