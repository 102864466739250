import {
  Timeline as unstyledMuiTimeline,
  TimelineProps as MuiTimelineProps,
  timelineContentClasses,
  timelineDotClasses,
  timelineConnectorClasses,
} from "@mui/lab";
import { styled } from "@mui/material";
import { timelineItemClasses } from "components/dialog/timeline/timelineItemClasses";

const MuiTimeline = styled(unstyledMuiTimeline)(({ theme }) => ({
  padding: 0,
  margin: 0,
  [`& .${timelineItemClasses.root}`]: {
    padding: theme.spacing(0, 0, 2, 5),
    minHeight: "auto",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    [`&.${timelineItemClasses.selectable}:hover`]: {
      backgroundColor: theme.palette.background.default,
      cursor: "pointer",
      transition: theme.transitions.create("background-color", {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeOut,
      }),
    },
    "&:before": {
      flex: 0,
      padding: 0,
    },
    [`& .${timelineDotClasses.root}`]: {
      boxShadow: "none",
      backgroundColor: theme.palette.surfaces.bodyDark,
      [`& svg`]: {
        color: theme.palette.text.secondary,
      },
    },
    [`& .${timelineItemClasses.itemTitle}`]: {
      color: theme.palette.text.primary,
      fontWeight: 600,
      padding: theme.spacing(1, 6, 0, 0),
    },
    [`& .${timelineItemClasses.itemDescription}`]: {
      maxWidth: 200,
    },
    [`& .${timelineItemClasses.imageWrapper}`]: {
      boxSizing: "content-box",
      borderRadius: theme.shape.borderRadius,
      border: `${theme.spacing(1)} solid ${theme.palette.customColors.light}`,
    },
    [`&.${timelineItemClasses.selected}`]: {
      backgroundColor: theme.palette.primary.lightest,
      borderLeft: 0,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      borderRight: `4px solid ${theme.palette.primary.main}`,
      borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
      [`& .${timelineDotClasses.root}`]: {
        backgroundColor: theme.palette.primary.main,
        [`& svg`]: {
          color: theme.palette.primary.contrastText,
        },
      },
      [`& .${timelineConnectorClasses.root}`]: {
        backgroundColor: theme.palette.primary.main,
      },
      [`& .${timelineItemClasses.imageWrapper}`]: {
        border: `${theme.spacing(1)} solid ${theme.palette.common.white}`,
      },
    },
    [`&.${timelineItemClasses.selectedTop}, &.${timelineItemClasses.selectedMiddle}`]:
      {
        borderBottomRightRadius: 0,
      },
    [`&.${timelineItemClasses.selectedBottom}, &.${timelineItemClasses.selectedMiddle}`]:
      {
        marginTop: -1, // hide the double border
        borderTopRightRadius: 0,
      },
    [`&.${timelineItemClasses.selectedBottom}`]: {
      marginTop: -1, // hide the double border
      opacity: 0.7,
      [`& .${timelineDotClasses.root}`]: {
        opacity: 0.75,
      },
      [`& .${timelineConnectorClasses.root}`]: {
        opacity: 0.75,
      },
      [`& .${timelineContentClasses.root}`]: {
        opacity: 0.75,
      },
    },
    [`&.${timelineItemClasses.groupItem}`]: {
      padding: theme.spacing(2, 5),
      backgroundColor: theme.palette.background.default,

      [`&.${timelineItemClasses.selectedMiddle}`]: {
        backgroundColor: theme.palette.primary.lightest,
        opacity: 0.7,
      },
    },
  },
}));

export type TimelineProps = MuiTimelineProps;
export default MuiTimeline;
