import useAuth from "hooks/useAuth";
import { useMemo } from "react";
import { getClient, Client } from "sdk";

interface NullableWhenNotAuth {
  nullableWhenNotAuth: true;
}
type UseSdkParams = NullableWhenNotAuth | undefined;

export default function useSdk(): Client;
export default function useSdk({
  nullableWhenNotAuth,
}: NullableWhenNotAuth): null | Client;

export default function useSdk(params?: UseSdkParams): null | Client {
  const auth = useAuth();
  const client = useMemo(
    () => (auth.isAuthenticated ? getClient(auth.accessToken) : null),
    [auth],
  );

  if (null === client && !params?.nullableWhenNotAuth) {
    throw new Error("SDK cannot be used without authentication");
  }

  return client;
}
