import Money from "@unkover/unkover-api-sdk/dist/Model/Payment/Money";
import useLocalization from "hooks/useLocalization";
import { ReactElement } from "react";

export interface FormattedMoneyProps {
  money: Money;
  currencyDisplay?: Intl.NumberFormatOptions["currencyDisplay"];
}
export default function FormattedMoney({
  money,
  currencyDisplay,
}: FormattedMoneyProps): ReactElement {
  const { formatNumber } = useLocalization();
  const amount = money.amount;
  const currency = money.currency;

  return (
    <>
      {formatNumber(amount, {
        style: "currency",
        currency,
        currencyDisplay,
      })}
    </>
  );
}
