import { darken, Theme, Components } from "@mui/material/styles";

// ** Util Import
import { hexToRGBA } from "utils/hex-to-rgba";

const Alert: Partial<Components<Theme>> = {
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: 5,
        "& .MuiAlertTitle-root": {
          marginBottom: 0,
        },
        "& a": {
          fontWeight: 500,
          color: "inherit",
        },
      },
      action: {
        alignItems: "center",
        paddingTop: 0,
        marginRight: 0,
      },
      standardSuccess: ({ theme }) => ({
        color: darken(theme.palette.success.main, 0.12),
        backgroundColor: hexToRGBA(theme.palette.success.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.success.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.success.main, 0.12),
        },
      }),
      standardInfo: ({ theme }) => ({
        color: darken(theme.palette.info.main, 0.12),
        backgroundColor: hexToRGBA(theme.palette.info.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.info.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.info.main, 0.12),
        },
      }),
      standardWarning: ({ theme }) => ({
        color: darken(theme.palette.warning.main, 0.12),
        backgroundColor: hexToRGBA(theme.palette.warning.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.warning.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.warning.main, 0.12),
        },
      }),
      standardError: ({ theme }) => ({
        color: darken(theme.palette.error.main, 0.12),
        backgroundColor: hexToRGBA(theme.palette.error.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.error.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.error.main, 0.12),
        },
      }),
      outlinedSuccess: ({ theme }) => ({
        borderColor: theme.palette.success.main,
        color: darken(theme.palette.success.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.success.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.success.main, 0.12),
        },
      }),
      outlinedInfo: ({ theme }) => ({
        borderColor: theme.palette.info.main,
        color: darken(theme.palette.info.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.info.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.info.main, 0.12),
        },
      }),
      outlinedWarning: ({ theme }) => ({
        borderColor: theme.palette.warning.main,
        color: darken(theme.palette.warning.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.warning.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.warning.main, 0.12),
        },
      }),
      outlinedError: ({ theme }) => ({
        borderColor: theme.palette.error.main,
        color: darken(theme.palette.error.main, 0.12),
        "& .MuiAlertTitle-root": {
          color: darken(theme.palette.error.main, 0.12),
        },
        "& .MuiAlert-icon": {
          color: darken(theme.palette.error.main, 0.12),
        },
      }),
      filled: ({ theme }) => ({
        fontWeight: 400,
        color: theme.palette.common.white,
      }),
    },
  },
};

export default Alert;
