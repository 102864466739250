import "@jymfony/util/lib/global-this";
import "@jymfony/util/lib/mixins";
import SDKClient from "@unkover/unkover-api-sdk/dist/Http/Client";
import Request from "@solido/atlante-js/lib/Requester/Request";
import InvalidResponse from "@solido/atlante-js/lib/Requester/Response/InvalidResponse";
import { ResponseInterface } from "@solido/atlante-js/lib/Requester/Response/ResponseInterface";

import UnauthorizedError from "exceptions/UnauthorizedError";
import UnprocessableContentResponse from "@unkover/unkover-api-sdk/dist/Requester/Response/UnprocessableContentResponse";
import ValidationFailedError from "services/validation/errors/ValidationFailedError";
import ForbiddenError from "exceptions/ForbiddenError";
import PreconditionFailed from "exceptions/PreconditionFailed";

export default class Client extends SDKClient {
  protected _filterResponse(response: ResponseInterface, request: Request) {
    switch (true) {
      case response instanceof UnprocessableContentResponse:
        throw ValidationFailedError.from(
          response as UnprocessableContentResponse,
        );
      case response instanceof InvalidResponse:
        if (response.getStatusCode() === 401) {
          throw new UnauthorizedError();
        }
        if (response.getStatusCode() === 403) {
          throw new ForbiddenError(response);
        }
        if (response.getStatusCode() === 412) {
          throw new PreconditionFailed(response);
        }
        break;
    }

    super._filterResponse(response, request);
  }
}
