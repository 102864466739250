import { useCallback, useState } from "react";

type ThrowAsyncError = (error: unknown) => void;

export default function useThrowAsyncError(): ThrowAsyncError {
  const [, setState] = useState();

  return useCallback((error) => {
    setState(() => {
      throw error;
    });
  }, []);
}
