import TrackerInterface from "services/analytics/TrackerInterface";

export default class GTMTracker implements TrackerInterface {
  constructor(private readonly dataLayer: Record<string, unknown>[]) {}

  identify(): void {
    // not implemented yet
  }

  track(event: string, properties?: Record<string, unknown>): void {
    this.dataLayer.push({
      event,
      ...properties,
    });
  }

  page(): void {
    // not implemented yet
  }

  reset(): void {
    // not implemented
  }
}
