import { AlertContext } from "contexts/AlertContext";
import { useContext } from "react";

export default function useAlert() {
  const context = useContext(AlertContext);
  if (undefined === context) {
    throw new Error("useAlert must be used within a AlertProvider");
  }

  return context;
}
