import { Components, Theme } from "@mui/material";

const DataGrid: Partial<Components<Theme>> = {
  MuiDataGrid: {
    styleOverrides: {
      root: ({ theme }) => ({
        border: 0,
        color: theme.palette.text.primary,
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
          {
            outline: "none",
          },
      }),
      toolbarContainer: ({ theme }) => ({
        paddingRight: `${theme.spacing(5)} !important`,
        paddingLeft: `${theme.spacing(3.25)} !important`,
      }),
      columnHeaders: ({ theme }) => ({
        lineHeight: "24px !important",
        backgroundColor: theme.palette.customColors.tableHeaderBg,
      }),
      columnHeader: ({ theme }) => ({
        "&:not(.MuiDataGrid-columnHeaderCheckbox)": {
          padding: theme.spacing(4),
          "&:first-of-type": {
            paddingLeft: theme.spacing(5),
          },
        },
        "&:last-of-type": {
          paddingRight: theme.spacing(5),
        },
      }),
      columnHeaderCheckbox: {
        maxWidth: "58px !important",
        minWidth: "58px !important",
      },
      columnHeaderTitleContainer: {
        padding: 0,
      },
      columnHeaderTitle: {
        fontWeight: 600,
        fontSize: "0.75rem",
        letterSpacing: "0.17px",
        textTransform: "uppercase",
      },
      columnSeparator: ({ theme }) => ({
        color: theme.palette.divider,
      }),
      row: {
        "&:last-child": {
          "& .MuiDataGrid-cell": {
            borderBottom: 0,
          },
        },
      },
      cell: ({ theme }) => ({
        lineHeight: "20px !important",
        borderColor: theme.palette.divider,
        "&:not(.MuiDataGrid-cellCheckbox)": {
          padding: theme.spacing(4),
          "&:first-of-type": {
            paddingLeft: theme.spacing(5),
          },
        },
        "&:last-of-type": {
          paddingRight: theme.spacing(5),
        },
        "&:focus, &:focus-within": {
          outline: "none",
        },
      }),
      cellCheckbox: {
        maxWidth: "58px !important",
        minWidth: "58px !important",
      },
      editInputCell: ({ theme }) => ({
        padding: 0,
        color: theme.palette.text.primary,
        "& .MuiInputBase-input": {
          padding: 0,
        },
      }),
      footerContainer: ({ theme }) => ({
        minHeight: "50px !important",
        borderTop: `1px solid ${theme.palette.divider}`,
        "& .MuiTablePagination-toolbar": {
          minHeight: "50px !important",
          paddingLeft: `${theme.spacing(4)} !important`,
          paddingRight: `${theme.spacing(4)} !important`,
        },
        "& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel":
          {
            color: theme.palette.text.primary,
          },
      }),
      selectedRowCount: ({ theme }) => ({
        margin: 0,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      }),
    },
    defaultProps: {
      rowHeight: 50,
    },
  },
};

export default DataGrid;
