import { Settings } from "luxon";
import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";

interface LocalizationContext {
  locale: Intl.UnicodeBCP47LocaleIdentifier;
  setLocale: (locale: Intl.UnicodeBCP47LocaleIdentifier) => void;
  dateToLocaleString: (
    date: Date,
    options?: Intl.DateTimeFormatOptions,
  ) => string;
  formatNumber: (number: number, options?: Intl.NumberFormatOptions) => string;
}

export const LocalizationContext = createContext<
  LocalizationContext | undefined
>(undefined);

export function LocalizationProvider({
  children,
  defaultLocale,
}: {
  children: ReactNode;
  defaultLocale?: Intl.UnicodeBCP47LocaleIdentifier;
}): ReactElement {
  const [locale, setLocale] = useState<Intl.UnicodeBCP47LocaleIdentifier>(
    defaultLocale ?? "en-US",
  );

  useEffect(() => {
    Settings.defaultLocale = locale;
  }, [locale]);

  return (
    <LocalizationContext.Provider
      value={{
        locale,
        setLocale,
        dateToLocaleString: (date, options) =>
          date.toLocaleString(locale, options),
        formatNumber: (number, options) =>
          new Intl.NumberFormat(locale, options).format(number),
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
}
