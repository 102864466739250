import { Components, Theme } from "@mui/material";

const Button: Partial<Components<Theme>> = {
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 500,
        lineHeight: 1.71,
        letterSpacing: "0.3px",
        padding: theme.spacing(1.875, 3),
      }),
      contained: ({ theme }) => ({
        padding: theme.spacing(1.875, 5.5),
      }),
      outlined: ({ theme }) => ({
        padding: theme.spacing(1.625, 5.25),
        "&:hover": {
          backgroundColor: theme.palette.secondary.outlinedOverBg,
        },
      }),
      text: ({ theme }) => ({
        color: theme.palette.text.secondary,
        "&:hover": {
          backgroundColor: theme.palette.secondary.outlinedOverBg,
        },
      }),
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(1, 2.25),
        "&.MuiButton-contained": {
          padding: theme.spacing(1, 3.5),
        },
        "&.MuiButton-outlined": {
          padding: theme.spacing(0.75, 3.25),
        },
      }),
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(3.75, 8),
        "&.MuiButton-contained": {
          padding: theme.spacing(3.75, 8),
        },
        "&.MuiButton-outlined": {
          padding: theme.spacing(3.75, 8),
        },
      }),
    },
  },
};

export default Button;
